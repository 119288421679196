import React from "react";
// Customizable Area Start
import {
    Box,
    Typography,
    Grid,
    styled,
    Button,
    Card,
    CardContent,
    Accordion,
    AccordionSummary,
    IconButton,
    Avatar,
    Stack,
    AccordionDetails,
    Dialog,
    DialogTitle,
    Divider,
    Autocomplete,
    TextField
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LandingPageController, { Props ,Task} from "../LandingPageController";
import {
    crossIcon, checkIcon, checkListIcon, schedulingIcon, bookStatusIcon, airPortIcon, dateIcon,
    mailIcon, testsIcon, timeFrameIcon, timeZoneIcon, userTypeIcon, checkRideIcon,uploadIcon,successIcon,cancleIcon
} from "../assets";
import LinearProgress from '@mui/material/LinearProgress';
import CloseIcon from "@mui/icons-material/Close";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import MoreVertIcon from '@mui/icons-material/MoreVert';

// Customizable Area End

export default class LandingPage extends LandingPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderHeader = () => {
        return (
            <Box className="headerContainer">
                <Typography className="headerText">Hi, John!</Typography>
            </Box>
        )
    };

    renderPastExams = () => {
        return (
            <>
                <Grid item xs={12}>
                    <Card elevation={0} className="cardContainer">
                        <CardContent >
                            <Typography className="checkrideTitleText" >
                                Past exams
                            </Typography>
                        </CardContent>
                        <Box className="emptyExamContainer">
                            <Box className="scheduleContainer">
                                <img src={schedulingIcon} alt="image" />
                            </Box>
                            <Typography className="emptyMessage">
                                No past exams yet!
                            </Typography>
                            <Typography className="subEmptyMessage">Once you've completed an appointment, it'll be listed here.</Typography>
                        </Box>
                    </Card>
                </Grid>
                </>
        )
    };

    renderPastExamsData = () => {
        const  past_exams  = this.state.examData.past_exams;
        const { pastExamIndex } = this.state;
        const pastExam = past_exams[pastExamIndex];
        return (
            <Grid item xs={12}>
                <Card className="cardContainer">
                    <CardContent>
                        <Box className="pastExamTitle">
                            <Box className="countNumber">
                                <Typography data-testId = "pastExamText" className="checkrideTitleText">
                                    Past exams
                                </Typography>
                                <Box className="countlist"><span>{this.state.examData.past_exams_count}</span></Box>
                            </Box>

                            <Box className="arrowBtnContainer" >
                                <Button data-testId = "pastPreviousBtnTestId" onClick={this.handlePastPreviousExam}
                                    disabled={this.state.pastExamIndex === 0 ? true : false} >
                                    <ArrowBackIosNewIcon
                                        style={{ opacity: this.state.pastExamIndex === 0 ? 0.5 : 1 }}
                                        className="arrowButton" />
                                </Button>
                                <Button data-testId = "pastNextBtnTestId" onClick={this.handlePastNextExam}
                                    disabled={this.state.pastExamIndex === this.state.examData.past_exams.length - 1 ? true : false} >
                                    <ArrowForwardIosIcon
                                        style={{
                                            opacity: this.state.pastExamIndex === this.state.examData.past_exams.length - 1 ? 0.5 : 1
                                        }}
                                        className="arrowButton"  />
                                </Button>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                mt: 2,
                                backgroundColor: "#E5EDEF",
                                borderRadius: 3,
                                boxShadow: "none",
                                padding: "15px"
                            }}
                        >
                            <Box>
                                <Typography variant="subtitle1" fontWeight="bold" className="testName" style={{ color: "#334155" }}>
                                    Private - Airplane - Single Engine Land
                                </Typography>
                                <Divider style={{ marginTop: "10px", marginBottom: "10px", height: "1px", }} />
                                <Grid container className="gridContainer">
                                    <Grid item sm={6} md={12} lg={6}>
                                        <Stack direction="row" alignItems="center" spacing={1} mt={1}>
                                            <CalendarTodayIcon className="iconColorStyle" />
                                            <Typography className="pastSchedule pastExamBlackColor">
                                              {pastExam.availability_date}
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item sm={6} md={12} lg={6}>
                                        <Stack direction="row" alignItems="center" spacing={1} mt={1}>
                                            <FlightTakeoffIcon className="iconColorStyle" />
                                            <Typography className="pastSchedule pastExamBlackColor">
                                               {pastExam.airport == undefined ?"KBTF":pastExam.airport} 
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item sm={6} md={12} lg={6}>
                                        <Stack direction="row" alignItems="center" spacing={1} mt={1}>
                                            <AccessTimeIcon className="iconColorStyle" />
                                            <Typography className="pastSchedule pastExamBlackColor">
                                               {pastExam.time}
                                            </Typography>
                                        </Stack>
                                    </Grid >

                                    <Grid item sm={6} md={12} lg={6}>
                                        <Stack direction="row" alignItems="center" spacing={1} mt={1}>
                                            <Avatar className="userSortNmae">
                                                JD
                                            </Avatar>
                                            <Typography variant="body2" color="text.secondary" className="pastSchedule pastExamBlackColor">
                                                {pastExam.username}
                                            </Typography>
                                        </Stack>
                                    </Grid >
                                </Grid>
                            </Box>
                            <Box>
                                <Accordion elevation={0} style={{ backgroundColor: "#EFF4F5", borderRadius: "15px", marginTop: "15px" }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                    >
                                        <Typography component="span" className="checkRideCondition">How did the checkride go?</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container spacing={2} >
                                            <Grid item sm={12} lg={6}>
                                                <Button data-testId = "ipassedTestId" onClick={() => this.chackRideOutcomeOpenModal("I passed!")} className="pastExamButton pastgreenColorStyle">I passed!</Button>
                                            </Grid>
                                            <Grid item sm={12} lg={6}>
                                                <Button data-testId = "recheckRequeTestId" onClick={() => this.chackRideOutcomeOpenModal("Re-check required")} className="pastExamButton pastyellowColorStyle">Re-check required</Button>
                                            </Grid>
                                            <Grid item sm={12} lg={6}>
                                                <Button data-testId = "discontinudeTestId" onClick={() => this.chackRideOutcomeOpenModal("Checkride discontinued")} className="pastExamButton pastyellowColorStyle">Checkride discontinued</Button>
                                            </Grid>
                                            <Grid item sm={12} lg={6}>
                                                <Button data-testId = "occurTestId" onClick={() => this.chackRideOutcomeOpenModal("Checkride did not occur")} className="pastExamButton pastredColorStyle">Checkride did not occur</Button>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Box>
                        </Box>

                    </CardContent>
                </Card>
            </Grid >
        )
    };

    renderUpcomingExamData = () => {
        const  past_exams  = this.state.examData.upcoming_exams;
        const { upcomingExamIndax } = this.state;
        const upcomingExam = past_exams[upcomingExamIndax];
        return (
            <Grid item xs={12}>
                <Card className="cardContainer">
                    <CardContent>
                        <Box className="pastExamTitle">
                            <Box className="countNumber">
                                <Typography data-testId = "upcomingTextId" className="checkrideTitleText">
                                    Upcoming exams
                                </Typography>
                                <Box className="countlist"><span>{this.state.examData.upcoming_exams_count}</span></Box>
                            </Box>
                            <Box className="arrowBtnContainer">
                                <Button data-testId = "upcomimgPreviousBtnTestId" onClick={this.handleUpcomPreviousExam}
                                    disabled={upcomingExamIndax === 0 ? true : false} >
                                    <ArrowBackIosNewIcon
                                        style={{ opacity: upcomingExamIndax === 0 ? 0.5 : 1 }}
                                        className="arrowButton" />
                                </Button>
                                <Button data-testId = "upcomingNextBtnTestId" onClick={this.handleUpcomNextExam}
                                    disabled={upcomingExamIndax === this.state.examData.upcoming_exams.length - 1 ? true : false} >
                                    <ArrowForwardIosIcon
                                        style={{
                                            opacity: upcomingExamIndax === this.state.examData.upcoming_exams.length - 1 ? 0.5 : 1
                                        }}
                                        className="arrowButton"  />
                                </Button>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                mt: 2,
                                backgroundColor: "#004E64",
                                borderRadius: 3,
                                boxShadow: "none",
                                padding: "15px"
                            }}
                        >
                            <Box>
                                <Typography variant="subtitle1" fontWeight="bold" className="testName" style={{ color: "#FFFFFF" }}>
                                    Private - Airplane - Single Engine Land
                                </Typography>
                                <Divider style={{ marginTop: "10px", marginBottom: "10px", height: "1px" }} />
                                <Grid container className="gridContainer">
                                    <Grid item xs={12}  sm={6} md={12} lg={6}>
                                        <Stack direction="row" alignItems="center" spacing={1} mt={1}>
                                            <CalendarTodayIcon className="upcomingIconStyles" />
                                            <Typography variant="body2" className="pastSchedule upcomingExamWhiteColor" >
                                                {upcomingExam.availability_date}
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item sm={6} md={12} lg={6}>
                                        <Stack direction="row" alignItems="center" spacing={1} mt={1}>
                                            <FlightTakeoffIcon className="upcomingIconStyles" />
                                            <Typography variant="body2" className="pastSchedule upcomingExamWhiteColor">
                                            {upcomingExam.airport == undefined ?"KBTF":upcomingExam.airport} 
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item sm={6} md={12} lg={6}>
                                        <Stack direction="row" alignItems="center" spacing={1} mt={1}>
                                            <AccessTimeIcon className="upcomingIconStyles" />
                                            <Typography variant="body2" className="pastSchedule upcomingExamWhiteColor">
                                            {upcomingExam.time}
                                            </Typography>
                                        </Stack>
                                    </Grid >

                                    <Grid item sm={6} md={12} lg={6}>
                                        <Stack direction="row" alignItems="center" spacing={1} mt={1}>
                                            <Avatar className="userSortNmae">
                                                JD
                                            </Avatar>
                                            <Typography variant="body2" className="pastSchedule upcomingExamWhiteColor">
                                            {upcomingExam.username}
                                            </Typography>
                                        </Stack>
                                    </Grid >
                                </Grid>
                            </Box>
                            <Box>
                                <button data-testId="viewDetailsButton" onClick={()=>this.handleStatusOpenModal(upcomingExam)} className="upcomingViewDetailsBtn">
                                    View Details
                                </button>
                            </Box>
                        </Box>

                    </CardContent>
                </Card>
            </Grid >
        )
    }

    renderUpcomingExam = () => {
        return (
            <>
                <Grid item xs={12}>
                    <Card elevation={0} className="cardContainer">
                        <CardContent >
                            <Typography className="checkrideTitleText" >
                                Upcoming exams
                            </Typography>
                        </CardContent>
                        <Box className="emptyExamContainer">
                            <Box className="scheduleContainer">
                                <img src={schedulingIcon} alt="image" />
                            </Box>
                            <Typography className="emptyMessage">
                                Nothing booked yet!
                            </Typography>
                            <Typography className="subEmptyMessage">Once you reserve a time slot, it'll show up here.</Typography>
                            <Button className="scheduleButton">
                                Schedule your exam
                            </Button>
                        </Box>
                    </Card>
                </Grid>
            </>
        )
    }

    renderCheckRideOption = (trainingChecklist: Task[]) =>{
        return(<>
         {trainingChecklist.map((answer: Task) => {
            return (
            <Box>
                <Button data-testId="renderbutton6"  >
                    <input type="checkbox" id={answer.id} value={answer.attributes.description} checked ={answer.attributes.is_checked}/>
                    <label className="selectOption" htmlFor={answer.attributes.description}>{answer.attributes.description}</label>
                </Button>
            </Box>)
        })}
        </>
        ) 
    }


    CheckrideDashboard = () => {
        const trainingChecklist = this.state.trainingChecklist
        const instructionsChecklist = this.state.instructionsChecklist
        const flightChecklist = this.state.flightChecklist
        const checklistRecord = this.state.checklistRecord
        return (
            <Box className="landingContainer">
                <Grid container spacing={6}>
                    <Grid item sm={12} md={6}>
                        <Card elevation={1} className="cardContainer">
                            <CardContent>
                                <Typography variant="h6" className="checkrideTitleText">
                                    Prepare for your checkride
                                </Typography>
                            </CardContent>
                            <Box className="reviewPopContainer">
                                <img src={checkIcon} className="iconStyle" />
                                <Typography variant="body2" className="reviewPop" >
                                    Review and complete each requirement below before any appointment. You can return anytime to track your progress!
                                </Typography>
                                <img src={crossIcon} className="iconStyle" />
                            </Box>
                            <Box>
                                <Accordion elevation={0} style={{ marginTop: "15px", marginBottom: "15px" }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                        style={{ marginTop: "15px", marginBottom: "15px" }}
                                    >
                                        <Box>
                                            <Box className="recordContainer">
                                                <Typography className="recordTitle">Training Records</Typography>
                                                <Typography className="recordTitle">
                                                    {`${this.handleCheckLength(trainingChecklist)}/ ${trainingChecklist.length}`}
                                                </Typography>
                                            </Box>
                                            <Typography className="recordQuestion">Please make sure your logbook contains the following ground and flight training:</Typography>
                                        </Box>
                                    </AccordionSummary>
                                    {this.renderCheckRideOption(trainingChecklist)}
                                </Accordion>
                                <Accordion elevation={0} style={{ marginTop: "15px", marginBottom: "15px" }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                        style={{ marginTop: "15px", marginBottom: "15px" }}
                                    >
                                        <Box>
                                            <Box className="recordContainer">
                                                <Typography className="recordTitle">Instructor Endorsements</Typography>
                                                <Typography className="recordTitle">
                                                    {`${this.handleCheckLength(instructionsChecklist)}/ ${instructionsChecklist.length}`}
                                                </Typography>
                                            </Box>
                                            <Typography className="recordQuestion">Please make sure your instructor has given you the following endorsements from AC 61.65:</Typography>
                                        </Box>
                                    </AccordionSummary>
                                    {this.renderCheckRideOption(instructionsChecklist)}
                                </Accordion>
                                <Accordion elevation={0} style={{ marginTop: "15px", marginBottom: "15px" }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                        style={{ marginTop: "15px", marginBottom: "15px" }}
                                    >
                                        <Box>
                                            <Box className="recordContainer">
                                                <Typography className="recordTitle">Flight Experience</Typography>
                                                <Typography className="recordTitle">
                                                    {`${this.handleCheckLength(flightChecklist)}/ ${flightChecklist.length}`}
                                                </Typography>
                                            </Box>
                                            <Typography className="recordQuestion">Please make sure your logbook contains at least the following aeronautical experience FAR 61.109</Typography>
                                        </Box>
                                    </AccordionSummary>
                                    {this.renderCheckRideOption(flightChecklist)}
                                </Accordion>
                            </Box>
                            <Box className="progressContainer">
                                <Box className="progressSubCon">
                                    <Box sx={{ width: '100%', margin: "15px 0px" }}>
                                        <LinearProgress variant="determinate"
                                            sx={{ height: 5, borderRadius: 5 }} value={this.handleProgress(this.handleCheckLength(checklistRecord),checklistRecord.length)} />
                                    </Box>
                                    <Typography className="selectOptionStyle">{`${this.handleCheckLength(checklistRecord)}/ ${checklistRecord.length}`}</Typography>
                                </Box>
                                <Button fullWidth className="checkListBtn" data-testId = "goToCheckrideTestId" onClick={() => this.navigateScreens("ChecklList")}>
                                    Go to checklist <img src={checkListIcon} className="iconStyle" style={{ paddingLeft: "5px" }} />
                                </Button>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <Grid container spacing={6}>
                            {this.state.examData &&  this.state.examData.past_exams.length === 0 ? this.renderPastExams(): this.renderPastExamsData() }
                            { this.state.examData &&  this.state.examData.upcoming_exams.length === 0 ? this.renderUpcomingExam() : this.renderUpcomingExamData()}
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        );
    };

    renderAvailabilityModal = () => {
        const selectUpcoming = this.state.selectUpcoming
        return (
            <DialogComponent
                data-testId="openAvailabilityModal"
                aria-labelledby="customized-dialog-title"
                open={this.state.statusModal}
                onClose={this.handleStatusCloseModal}
                PaperProps={{
                    sx: { width: "700px", height: "600px", borderRadius: "20px", },
                }}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Availability
                </DialogTitle>
                <IconButton data-testId="closeAvailabilityModal" onClick={this.handleStatusCloseModal}
                    aria-label="close"
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme.palette.grey[500],
                    })}
                >
                    <CloseIcon />
                </IconButton>
                < Divider />
                <Grid container spacing={3} style={{ padding: "10px 0px 3px 25px" }}>
                    <Grid item style={{ alignContent: "end", paddingBottom: "4px" }}>
                        <Box style={{ width: "22px", height: "22px", }}><img src={bookStatusIcon} /></Box></Grid>
                    <Grid item>
                        <Typography className="statusTextStyle">STATUS</Typography>
                        <Typography className="statusItemStyle">Booked</Typography>
                    </Grid>
                </Grid>
                <Divider />
                <Grid container spacing={3} style={{ padding: "10px 0px 3px 25px" }}>
                    <Grid item style={{ alignContent: "end", paddingBottom: "4px" }}>
                        <Box style={{ width: "22px", height: "22px", }}><img src={userTypeIcon} /></Box></Grid>
                    <Grid item >
                        <Typography className="statusTextStyle">DPE</Typography>
                        <Box style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            paddingRight: "10px"
                        }}>
                            <Box style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                gap: 10
                            }}>
                                <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" style={{ width: "28px", height: "28px" }} />
                                <Typography style={{
                                    color: "#0074BF", fontFamily: "Montserrat",
                                    fontWeight: 500,
                                    fontSize: "16px",
                                    lineHeight: "24px"

                                }}>{selectUpcoming.username}</Typography>
                            </Box>
                            <Box>
                                <img src={mailIcon} style={{ width: "20px", height: "16px" }} />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={3} style={{ padding: "10px 0px 3px 25px" }}>
                    <Grid item style={{ alignContent: "end", paddingBottom: "4px" }}>
                        <Box style={{ width: "22px", height: "22px", }}><img src={dateIcon} /></Box></Grid>
                    <Grid item>
                        <Typography className="statusTextStyle">DATE</Typography>
                        <Typography className="statusItemStyle">{selectUpcoming.availability_date}</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={3} style={{ padding: "10px 0px 3px 25px" }}>
                    <Grid item style={{ alignContent: "end", paddingBottom: "4px" }}>
                        <Box style={{ width: "22px", height: "22px", }}><img src={timeFrameIcon} /></Box></Grid>
                    <Grid item>
                        <Typography className="statusTextStyle">TIME FRAME</Typography>
                        <Typography className="statusItemStyle">{selectUpcoming.time}</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={3} style={{ padding: "10px 0px 3px 25px" }}>
                    <Grid item style={{ alignContent: "end", paddingBottom: "4px" }}>
                        <Box style={{ width: "22px", height: "22px", }}><img src={timeZoneIcon} /></Box></Grid>
                    <Grid item>
                        <Typography className="statusTextStyle">TIME ZONE</Typography>
                        <Typography className="statusItemStyle">Eastern Standard Time (EST)</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={3} style={{ padding: "10px 0px 3px 25px" }}>
                    <Grid item style={{ alignContent: "end", paddingBottom: "4px" }}>
                        <Box style={{ width: "22px", height: "22px", }}><img src={airPortIcon} /></Box></Grid>
                    <Grid item>
                        <Typography className="statusTextStyle">AIRPORT</Typography>
                        <Typography className="statusItemStyle">KBTF</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={3} style={{ padding: "10px 0px 3px 25px" }}>
                    <Grid item style={{ alignContent: "end", paddingBottom: "4px" }}>
                        <Box style={{ width: "22px", height: "22px", }}><img src={testsIcon} /></Box></Grid>
                    <Grid item>
                        <Typography className="statusTextStyle">TESTS</Typography>
                        <Typography className="statusItemStyle">Private - Airplane - Single Engine Land, +1 more</Typography>
                    </Grid>
                </Grid>
            </DialogComponent>
        )
    }

    checkRideGoModal = () => {
        return (
            <CheckRideComponent
                data-testId="openAvailabilityModal"
                aria-labelledby="customized-dialog-title"
                open={this.state.checkRideGoModal}
                onClose={this.checkRideGoCloseModal}
                PaperProps={{
                    sx: { width: "700px", height: "auto", borderRadius: "20px", },
                }}
            >
                <DialogTitle className="checkRideTitle" sx={{ m: 0, p: 2, gap: 2 }} id="customized-dialog-title">
                    <img src={checkRideIcon} />
                    How did the checkride go?
                </DialogTitle>
                <IconButton data-testId="closeAvailabilityModal" onClick={this.checkRideGoCloseModal}
                    aria-label="close"
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme.palette.grey[500],
                    })}
                >
                    <CloseIcon />
                </IconButton>
                < Divider />

                <Box style={{ padding: "10px 30px", gap: 10 }}>
                    <Typography className="questionItemStyle">Tell us how it went! Choose an option below to update your status:</Typography>
                    <Box>
                        <Button className="checkRideButton greenColorStyle">I passed!</Button>
                        <Button className="checkRideButton yellowColorStyle">Re-check required</Button>
                        <Button className="checkRideButton yellowColorStyle">Checkride discontinued</Button>
                        <Button className="checkRideButton redColorStyle">Checkride did not occur</Button>
                        <Box className="skipButton" onClick={this.checkRideGoCloseModal}>Skip for now</Box>
                    </Box>
                </Box>
            </CheckRideComponent>
        )
    };

    CheckrideOutcomeModal = () => {
        return (
            <CheckOutcomComponent
                data-testId="checkRideOutcomModal"
                aria-labelledby="customized-dialog-title"
                open={this.state.CheckRideOutcome}
                onClose={this.chackRideOutcomeCloseModal}
                PaperProps={{
                    sx: { width: "497px", height: "auto", borderRadius: "20px", paddingBottom: "10px" },
                }}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title" className="checkrideTitle">
                    Checkride outcome
                </DialogTitle>
                <IconButton  data-testId = "checkRideOutCloseTestID" onClick={this.chackRideOutcomeCloseModal}
                    aria-label="close"
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme.palette.grey[500],
                    })}
                >
                    <CloseIcon />
                </IconButton>
                < Divider style={{ margin: "10px 0px" }} />
                <Box style={{ display: "flex", flexDirection: "column", gap: 7, padding: "10px 30px" }}>
                    <Grid container spacing={3} >
                        <Grid item>
                            {this.state.selectStatus && 
                             <Typography style={{
                                fontFamily: "Montserrat",
                                fontWeight: 700,
                                fontSize: "10px",
                                lineHeight: "14px",
                                color:" #F59E0B"
                            }}>{this.convertToUpperCase(this.state.statusValue)}</Typography>}
                           
                            <Typography className="subjectTest">Private - Airplane - Single Engine Land</Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} >
                        <Grid item >
                            <img src={dateIcon} className="checkRideiconStyle" />
                        </Grid>
                        <Grid item>
                            <Typography className="checkrideStatus">Friday, August 3, 2024</Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} >
                        <Grid item style={{ alignContent: "end", paddingBottom: "4px" }}>
                            <img src={timeFrameIcon} className="checkRideiconStyle" />
                        </Grid>
                        <Grid item>
                            <Typography className="checkrideStatus">10:00 - 11:00 AM</Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} >
                        <Grid item >
                            <img src={timeZoneIcon} className="checkRideiconStyle" />
                        </Grid>
                        <Grid item>
                            <Typography className="checkrideStatus">Eastern Standard Time (EST)</Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} >
                        <Grid item >
                            <img src={airPortIcon} className="checkRideiconStyle" />
                        </Grid>
                        <Grid item>
                            <Typography className="checkrideStatus">KBTF</Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} >
                        <Grid item >
                            <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" style={{ width: "20px", height: "20px" }} />
                        </Grid>
                        <Grid item>
                            <Typography className="checkrideStatus">John Doe</Typography>
                        </Grid>
                    </Grid>
                </Box>
                < Divider style={{ margin: "10px 0px" }} />
                <Box style={{ padding: "10px 20px" }}>
                    {!this.state.selectStatus ? this.renderStatusOption() : this.reCheckRequriedStatus()}
                </Box>
            </CheckOutcomComponent>
        )
    };

    renderStatusOption = () => {
        return (
            <>
                <Typography className="subjectTest">Status</Typography>
                <Autocomplete
                    popupIcon={<ExpandMoreIcon style={{ color: "#94A3B8" }} />}
                    disablePortal
                    data-testId = "selectStatusTestId"
                    onInputChange={this.handleOption}
                    autoHighlight
                    clearOnEscape
                    disableCloseOnSelect={false}
                    defaultValue={this.state.statusValue}
                    clearOnBlur={false}
                    clearIcon={null}
                    options={this.state.status}
                    sx={{
                        '& .MuiInputBase-root': {
                            border: '1px solid #CBD5E1',
                            borderRadius: '25px',
                            paddingRight: '30px !important',
                            '&:hover': {
                                border: '1px solid #0074BF',
                            },
                        },
                        width: "100%",
                    }}
                    renderInput={(params) => <TextField  {...params} placeholder="Select status" />}
                />
                {this.state.statusValue == "Checkride discontinued" &&
                    <>
                        <Typography className="subjectTest">Why didn't the checkride occur?</Typography>
                        <Autocomplete
                            popupIcon={<ExpandMoreIcon style={{ color: "#94A3B8" }} />}
                            disablePortal
                            disableCloseOnSelect={false}
                            defaultValue={this.state.statusValue}
                            clearOnBlur={false}
                            clearIcon={null}
                            options={this.state.reason}
                            sx={{
                                '& .MuiInputBase-root': {
                                    border: '1px solid #CBD5E1',
                                    borderRadius: '25px',
                                    paddingRight: '30px !important',
                                    '&:hover': {
                                        border: '1px solid #0074BF',
                                    },
                                },
                                width: "100%",
                            }}
                            renderInput={(params) => <TextField  {...params} placeholder="Select status" />}
                        />
                    </>
                }

                <Typography className="uploadSubTitle">Please upload the document provided by your DPE indicating the requirement for a re-check.</Typography>
                {!this.state.uploadFileSelected &&
                    <Button className="uploadBtn" fullWidth component="label">
                       <img src= {uploadIcon}/> Upload document
                        <input data-testId = "uploadDocumentTestId" type="file" hidden onChange={this.handleUploadFile} />
                    </Button>}
                {this.state.uploadFileSelected &&
                    <Box className="selectDocument">
                        <Typography data-testId = "uploadDocumentId"> {this.state.uploadFileSelected.name}</Typography>
                        <MoreVertIcon />
                    </Box>}
                <Typography className="submitMessage">By submitting, you agree that DPEs will be notified of your re-check need and able to direct message you through the app.</Typography>
                <Button className="submitBtn" data-testId = "submitBtnTestId" onClick={this.handleSubmitStatu}>
                    Submit
                </Button>
            </>
        )
    };

    reCheckRequriedStatus = () => {
        return (
            <>
                <Box style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: "17px 0px"
                }}>
                     {this.state.statusValue == "Checkride discontinued"?  <img src={cancleIcon} /> :<img src={successIcon} />}
                   
                </Box>
                {this.state.statusValue == "Checkride discontinued" && <>
                    <Typography className="selectOption">Your re-check requirement has been noted!</Typography>
                    <Typography className="goBackMessage">Your DPE has been notified. You can opt out anytime.</Typography>
                </>}
                {this.state.statusValue == "Re-check required" && <>
                    <Typography className="selectOption">Your re-check requirement has been noted!</Typography>
                    <Typography className="goBackMessage">Your DPE has been notified. You can opt out anytime.</Typography>
                </>}
                {this.state.statusValue == "Re-check cancle" && <>
                    <Typography className="selectOption">You have been removed from the re-check list.</Typography>
                </>}
                {this.state.statusValue == "Checkride discontinued" && <>
                    <Typography className="selectOption">Weve notified your DPE about the issue.</Typography>
                    <Typography className="goBackMessage">Once they confirm, youll be re-added to the waitlist and can rebook without charge.</Typography>
                </>}
                <Button className="submitBtn" data-testId = "backOptionBtn"  onClick={this.backStatusOption}>Go back</Button>
                <Typography className="otpOption" data-testId = "optionRideBtn" onClick={this.optOutWorningMessageOpenModal}>Opt out of re-check list</Typography>
            </>
        )
    };

    recheckCanceledStatus = () => {
        return (
            <>
                <Box style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: "17px 0px"
                }}>
                    <img src={mailIcon} />
                </Box>
                <Typography className="selectOption">You have been removed from the re-check list.</Typography>
            </>
        )
    };

    optOutWarningModal = () => {
        return (
            <CheckRideComponent
                data-testId="warningModalTestId"
                aria-labelledby="customized-dialog-title"
                open={this.state.isOptOutWorningMessage}
                onClose={this.optOutWorningMessageCloseModal}
                PaperProps={{
                    sx: { width: "497px", height: "auto", borderRadius: "20px", },
                }}
            >
                <DialogTitle className="checkRideTitle" sx={{ m: 0, p: 2, gap: 2 }} id="customized-dialog-title">
                    <img src={checkRideIcon} />
                    Opt out of re-check list?
                </DialogTitle>
                <IconButton data-testId="closeAvailabilityModal" onClick={this.optOutWorningMessageCloseModal}
                    aria-label="close"
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme.palette.grey[500],
                    })}
                >
                    <CloseIcon />
                </IconButton>
                < Divider />

                <Box style={{ padding: "10px 30px", gap: 10 }}>
                    <Typography className="questionItemStyle">Your DPE will no longer see you on the re-check list or be able to message you about scheduling.</Typography>
                    <Box>
                        <Button className="checkRideButton cancleBtn">Cancel</Button>

                        <Box className="skipButton">Yes, opt out of re-check list</Box>
                    </Box>
                </Box>
            </CheckRideComponent>
        )
    };


    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <LandingContainer>
                {this.renderHeader()}
                {this.CheckrideDashboard()}
                {this.checkRideGoModal()}
                {this.renderAvailabilityModal()}
                {this.CheckrideOutcomeModal()}
                {this.optOutWarningModal()}
            </LandingContainer>
        )
        // Customizable Area End
    }
}

// Customizable Area Start
const LandingContainer = styled(Box)({
    "& .landingContainer":{
        padding:"64px",
        "@media (max-width: 460px)": {
            padding:"0px",
        },
    },
    "& .pastExamButton": {
        fontFamily: "Montserrat",
        fontWeight: 700,
        fontSize: "12px",
        lineHeight: "22px",
        height: "40px",
        borderRadius: "20px",
        width: "100%",
        textTransform: "capitalize",
    },
    "& .pastgreenColorStyle": {
        color: "#10B981",
        backgroundColor: "#D1FAE5"
    },
    "& .pastyellowColorStyle": {
        color: "#F59E0B",
        backgroundColor: "#FEF3C7"
    },
    "& .pastredColorStyle": {
        color: "#F87171",
        backgroundColor: "#FEE2E2"
    },
    "& .headerContainer": {
        display: "flex",
        height: "60px",
        padding: "10px 50px 10px 50px",
        alignItems: "center",
        background: "#ffffff",
        justifyContent: "space-between"
    },
    "& .headerText": {
        fontFamily: "Montserrat",
        fontWeight: 700,
        fontSize: "22px",
        lineHeight: "28px",
        color: "#0074BF"
    },
    "& .cardContainer": {
        borderRadius: "20px",
        padding: "20px",
        boxShadow: "none",

    },
    "& .iconStyle": {
        width: "20px",
        height: "20px"
    },
    "& .checkrideTitleText": {
        color: "#001C55",
        fontFamily: "Montserrat",
        fontWeight: 700,
        fontSize: "16px",

    },
    "& .reviewPop": {
        color: "#94A3B8",
        fontFamily: "Montserrat",
        fontWeight: 500,
        fontSize: "12px",
        lineHeight: "18px",
    },
    "& .reviewPopContainer": {
        backgroundColor: "#F0F4FD",
        borderRadius: "20px",
        padding: "15px 30px",
        display: "flex",
        gap: "8px",
        justifyContent: "center",
        alignItems: "center"
    },
    "& .recordTitle": {
        color: "#94A3B8",
        fontFamily: "Montserrat",
        fontWeight: 700,
        fontSize: "12px",
        lineHeight: "22px",
        textTransform: "uppercase"
    },
    "& .recordQuestion": {
        fontFamily: "Montserrat",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "20px",
        color: "#94A3B8",
    },
    " .recordContainer": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width:"100%"
    },
    "& .selectOption": {
        fontFamily: "Montserrat",
        fontWeight: 400,
        fontSize: "15px",
        lineHeight: "24px",
        color: "#94A3B8",
        textTransform: "none",
    },
    "& .selectOptionStyle": {
        fontFamily: "Montserrat",
        width: "65px",
        textAlign: "center",
        fontSize: "15px",
        fontWeight: 400,
        color: "#94A3B8",
        textTransform: "none",
        lineHeight: "24px",
    },
    "& .MuiLinearProgress-root": {
        backgroundColor: "#E2E8F0"
    },
    "& .css-5xe99f-MuiLinearProgress-bar1": {
        backgroundColor: "#00AFEF"
    },
    "& .progressContainer": {
        padding: " 0px 13px 13px 13px"
    },
    "& .progressSubCon": {
        display: "flex",
        justifyContent: "space-between",
        gap: 40
    },
    "& .checkListBtn": {
        border: "1px solid #0074BF",
        height: "41px",
        fontFamily: "Montserrat",
        fontSize: "14px",
        fontWeight: 700,
        color: "#0074BF",
        borderRadius: "20px",
        marginTop: "15px",
        textTransform: "none"
    },
    "& .emptyExamContainer": {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        padding: "14px 0px"
    },
    "& .scheduleContainer": {
        width: "58px",
        height: "58px",
        borderRadius: "50%",
        backgroundColor: "#F1F5F9",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginButtom: "30px"
    },
    "& .emptyMessage": {
        fontFamily: "Montserrat",
        fontWeight: 600,
        fontSize: "14px",
        lineHeight: "17.07px",
        color: "#001C55"
    },
    "& .subEmptyMessage": {
        fontFamily: "Montserrat",
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "17.07px",
        letterSpacing: "0px",
        color: "#94A3B8"
    },
    "& .scheduleButton": {
        border: "1px solid #0074BF",
        height: "40px",
        width: "190px",
        fontFamily: "Montserrat",
        fontWeight: 700,
        fontSize: "14px",
        borderRadius: '20px',
        textTransform: "capitalize",
        marginTop: "15px"
    },
    "& .arrowButton": {
        width: "20px",
        height: "20px",
        color: "#94A3B8"
    },
    "& .pastExamTitle": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    "& .arrowBtnContainer": {
        display: "flex",
    },
    "& .iconColorStyle": {
        fontSize: 16,
        color: "gray",
        width:"16px",
        height:"16px"
    },
    "& .upcomingIconStyles": {
        fontSize: 16,
        color: "#E2E8F0",
        with: "16px",
        height:"16px"
    },
    "& .upcomingViewDetailsBtn": {
        width: "100%",
        height: "30px",
        backgroundColor: "#FFFFFF",
        textTransform: "none",
        borderRadius: "20px",
        marginTop: "15px",
        border: "none",
        color: "#0074BF",
        fontFamily: "Montserrat",
        fontWeight: 700,
        fontSize: "12px",
        lineHeight: "24px",
    },
    "& .countlist": {
        fontSize: "12px",
        color: "#94A3B8",
        width: "25px",
        height: "25px",
        borderRadius: "50%",
        backgroundColor: "#F1F5F9",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginLeft: "6px",
        fontFamily: "Montserrat",
        fontWeight: 700,
        lineHeight: "24px",
        textAlign: "center",
    },
    "& .countNumber": {
        display: "flex",
        alignItems: "center",
        gap: "3"
    },
    "& .gridContainer": {
        display: "flex",
        justifyContent: "space-between"
    },
    "& .testName": {
        fontFamily: "Montserrat",
        fontWeight: 600,
        fontSize: "15px",
        lineHeight: "100%",
    },
    "& .userSortNmae": {
        width: 24,
        height: 24,
        bgcolor: "#3B82F6",
        fontSize: 12
    },
    "& .pastSchedule": {
        fontFamily: "Montserrat",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "14px",
        
    },
    "& .pastExamBlackColor":{
        color: "#334155"
    },
    "& .upcomingExamWhiteColor":{
        color: "#FFFFFF"
    },
    "& .checkRideCondition": {
        fontFamily: "Montserrat",
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "100%",
        color: "#334155"
    }

})

const DialogComponent = styled(Dialog)({
    "& .statusTextStyle": {
        color: "#94A3B8",
        fontFamily: "Montserrat",
        fontWeight: 700,
        fontSize: "12px",
        lineHeight: "22px",

    },
    "& .statusItemStyle": {
        fontFamily: "Montserrat",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#475569"


    }
})

const CheckRideComponent = styled(Dialog)({
    "& .checkRideButton": {
        fontFamily: "Montserrat",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "22px",
        height: "50px",
        borderRadius: "20px",
        width: "100%",
        marginTop: "15px",
        textTransform: "none",
    },
    "& .greenColorStyle": {
        color: "#10B981",
        backgroundColor: "#D1FAE5"
    },
    "& .yellowColorStyle": {
        color: "#F59E0B",
        backgroundColor: "#FEF3C7"
    },
    "& .cancleBtn": {
        color: "#FFFFFF",
        backgroundColor: "#0074BF"
    },
    "& .redColorStyle": {
        color: "#F87171",
        backgroundColor: "#FEE2E2"
    },
    "& .questionItemStyle": {
        fontFamily: "Montserrat",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#475569",
        margin: "15px 0px"
    },
    "& .skipButton": {
        fontFamily: "Montserrat",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "24px",
        textAlign: "center",
        color: " #0074BF",
        margin: "15px 0px 30px 0px"
    },
    "& .checkRideTitle": {
        fontFamily: "Montserrat",
        fontWeight: 700,
        fontSize: "20px",
        lineHeight: "28px",
        color: " #001C55",
        display: "flex",
        alignItems: "center",

    }
})

const CheckOutcomComponent = styled(Dialog)({
    "& .checkrideTitle": {
        fontFamily: "Montserrat",
        fontWeight: 700,
        fontSize: "22px",
        lineHeight: "28px",
        color: "#0074BF"
    },
    "& .subjectTest": {
        fontFamily: "Montserrat",
        fontWeight: 600,
        fontSize: "15px",
        lineHeight: "18.29px",
        color: "#334155",
        marginBottom: "10px"
    },
    "& .checkRideiconStyle": {
        width: "16px",
        height: "16px"
    },
    "& .checkrideStatus": {
        fontFamily: "Montserrat",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "14px",
        color: "#64748B"
    },
    "& .uploadSubTitle": {
        fontFamily: "Montserrat",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#0074BF",
        margin: "10px 0px"
    },
    "& .uploadBtn": {
        border: "1px solid #0074BF",
        borderRadius: "20px",
        height: "45px",
        fontFamily: "Montserrat",
        fontWeight: 700,
        fontSize: "14px",
        lineHeight: "24px",
        color: "#0074BF",
        textTransform: "capitalize",
        margin: "15px 0px"
    },
    "& .selectDocument": {
        border: "1px solid #CBD5E1",
        borderRadius: "20px",
        height: "45px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0px 10px",
        margin: "15px 0px"
    },
    "& .submitMessage": {
        fontFamily: "Montserrat",
        fontWeight: 500,
        fontSize: "12px",
        lineHeight: "18px",
        color: "#94A3B8"
    },
    "& .submitBtn": {
        borderRadius: "20px",
        width: "100%",
        height: "45px",
        fontFamily: "Montserrat",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#FFFFFF",
        textTransform: "capitalize",
        margin: "18px 0px",
        backgroundColor: "#0074BF"
    },
    "& .selectOption": {
        fontFamily: "Montserrat",
        fontWeight: 700,
        fontSize: "19px",
        lineHeight: "24px",
        color: "#001C55",
        textAlign: "center",
        marginBottom: "10px"
    },
    "& .goBackMessage": {
        fontFamily: "Montserrat",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "19px",
        color: "#001C55",
        textAlign: "center"
    },
    "& .otpOption": {
        fontFamily: "Montserrat",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#0074BF",
        textAlign: "center",
        marginBottom: "10px"
    },

})
// Customizable Area End