import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import moment from "moment";
import {add, checkedBox, payment, remove, unCheckedBox, visacard,} from "./assets"
import { createRef } from "react";
import { isEqual } from "lodash";
import { Stripe, StripeElements } from "@stripe/stripe-js";

const NotifyUsers = [
  {
    name: "James Cooper",
    id: 1,
    image: "https://cdn-icons-png.flaticon.com/512/3135/3135715.png",
  },
  {
    name: "Mia Sullivan",
    id: 2,
    image: "",
  },
  {
    name: "Benjamin Rivera",
    id: 3,
    image: "https://cdn-icons-png.flaticon.com/512/3135/3135715.png",
  },
];
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  stripe?: Stripe | null;
  elements?: StripeElements | null;
  date?: any
  // Customizable Area End
}
interface CustomToolbarProps {
  label: string;
  onView: (view: string) => void;
  views: string[];
}
interface User {
  id: number;
  name: string;
  avatar: string;
  calendar: string;
}

interface Availability {
  id: number;
  start_time: string;
  end_time: string;
  availabilityAirport: string,
  availabilityTests: string;
  availability_date: string;
  available_slots_count: number;
  time_zone: string;
  status: string;
}

export interface Aircraft {
  id: string;
  name: string;
  selected: boolean;
}

export interface IAirport {
  id: number;
  service_subcategory_id: number;
  service_subcategory_name: string
}

export interface IDesignations {
  id: number;
  service_subcategory_id: number;
  service_subcategory_name: string
}

interface InitialFilters {
  grades: Aircraft[],
  classes: Aircraft[],
  categories: Aircraft[],
  aircrafts: Aircraft[],
  airports: Aircraft[],
}

export interface TestDetailsFile {
  id: number;
  name: string;
  url: string;
  size: string;
}

interface TestDetailsData {
  designation: string;
  exam_cost: string;
  accepted_payment_methods: string;
  instructions: string;
  pdf_files: TestDetailsFile[]
}

export interface SingleCardDetails {
  id: string;
  cardNumber: string;
  expiryDate: string;
  cardHolderName: string;
  cvv: string;
  isCardSelected? : boolean;
  selectedCardOpen?: boolean
}

interface AvailabilityData {
  availability: Availability | null;
  airports: any[];
  designations: any[];
}
type AlertType = 'success' | 'error' | 'warning' | 'info';
interface DataItem {
  id: number;
  description: string;
  selected: boolean;
}
interface S {
  // Customizable Area Start
  selectedTime: number;
  selectedDate: string;
  timeSlots: object[];
  serviceProviderId: string;
  serviceProviderSchedule: any;
  availability:any;
  filteredData:any;
  checkDes:any;
  filteredData1:any;
  joinWaitlistRes:any;
  carList:any;
  chatres:any;
  canceljoinWaitlist:any;
  all_dpe_account_count:any;
  filteredAccounts: any,
  showAvailableOnly: any,
  dpe_with_availability_count:any;
  events: any,
  details: any;
  token: any;
  date: any,
  view: any,
  open:boolean;
  isCalenderOpen:boolean;
  isSelectAirportOpen:boolean;
  isSelectTestTypeOpen:boolean;
  isNotifiyAll:boolean;
  availabilityDate: string;
  timeSlot: { starttime: string; endtime: string }[];
  selectedTimeSlotForEdit: { starttime: string; endtime: string  };
  timeZones: any[];
  selectedTimeZone: string;
  showtimeZoneOverlay: boolean;
  currentDate:any;
  isSetDetailsOpen: boolean;
  isNotifyContainerOpen: boolean;
  allAirports: any[];
  userSelectedAirports: any[];
  allTests: any[];
  paymentRes:any;
  userSelectedTests: any[];
  allMembers: any[];
  shouldNotifyAlluser:boolean;
  alertType: AlertType;
  alertMsg: string;
  isAlert: boolean;
  startDate: any, // Start of the current month
  endDate:any, // End of the current month
  userSelectedMembers: any[];
  isViewAvailabiliDialogOpen: boolean;
  selectedAvailability: AvailabilityData;
  isEditAvailabilityOpen:boolean;
  deleteAvailabilityDialogOpen: boolean;
  openDialog: boolean;
  editSelectedTests: any[];
  editselectedAirports: any[];
  availabilityId: number;
  isFiltersDialogOpen: boolean;
  anchorEl: any;
  isDpePopupOpen: any,
  anchorElDpe: any,

  isTestPopupOpen: boolean;
  isAircraftPopupOpen: boolean;
  isAirportPopupOpen: boolean;
  isGradePopupOpen: boolean,
  isCategoryPopupOpen: boolean,
  isClassPopupOpen: boolean,

  // Category data
  allGradeData: any;
  filteredGradeData: any;
  allCategoryData: any;
  filteredCategoryData: any;
  allClassData: any;
  filterClassData: any;

  // Aircrafts
  allAircrafts: Aircraft[];
  filteredAircrafts: Aircraft[];
  searchQuery: string;
  // Airports
  allAirportsForFilter: Aircraft[];
  filteredAirports: Aircraft[];
  searchQueryAirport: string;

  isResetEnabled: boolean;
  todayDate: string;
  selectedd: number[]; // Stores selected user IDs
  selectAll: any;
  initialFilters: InitialFilters;
  userRole: string;

  isViewApplicantsAvailabilityDialogOpen: boolean;
  isTermsChecked: boolean;
  isDpeTestTypeOpen: boolean;

  dpeAllTest: any[];
  depSelectedTests: IDesignations;

  isDpeAirportSelectOpen: boolean;
  dpeAllAirport: any[];
  depSelectedAirport: IAirport;

  dpeAvailabilityDate: string;
  dpeTimezone: string;
  dpeTimeslot: string;
  dpe_username: string;
  isLoading: boolean;
  dpeAvailabilityStatus: string;

  testDetailsData: TestDetailsData;
  isPaymentDialogOpen: boolean;
  cardDetails: SingleCardDetails;
  cardNumberError: string;
  errCardHolderName: string;
  errExpiryDate:string;
  errorInCardCVV: string;
  isRememberCardChecked: boolean;
  savedCard: SingleCardDetails[];
  userSelectedPaymentCard: SingleCardDetails;
  isNewCardClicked: boolean;
  isPaymentSuccessDialogOpen: boolean;
  isPaymentFailedDialogOpen: boolean;
  availabilityEdited: boolean;
  openSections: {
    training: boolean,
    instruction: boolean,
    flight: boolean
  };
  selectedItems: {
    [key: string]: boolean;
  };
  selectedItems1: {
    [key: string]: boolean;
  };
  selectedItems2: {
    [key: string]: boolean;
  };
  showBox: boolean, 
  showBox1:boolean,
  cardType: string
  joinedWaitlist: any,
  selectedMonth: any, // Default to the current month
  trainingChecklist: any,
  instructionsChecklist: any,
  flightChecklist: any,
  openEdit: boolean,
  openDialogEdit: boolean,
  cardholderName: string,
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class SchedulingController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  availabilityApiCallId: string="";
  availabilityfilterCalApiCallId: string= "";
  filterGetApiCallId: string="";
  filterGetApiCallId4: string="";
  availabilityApiCallId1: string=";"
  availabilityApiCallId5: string=";"
  availabilityApplicantApiCallId: string="";
  availabilityFilterApplicantApiCallId: string = "";
  serviceProviderDetailApiId: any;
  serviceProviderScheduleApiId: any;
  saveAvailablityApiCallId: string = "";
  chatApiPostApiCallId: string="";
  paymentApiCallId: string = "";
  joinWaitlistApiCallId: string ="";
  paymentsApiCallId: string = "";
  payDetailsApiCallId: string = "";
  defaultPayApiCallId: string = "";
  joinwasApiCallId: string= "";
  getAirportsApiCallId: string = "";
  getAllSelectedTestsApiCallId: string = "";
  fetchAvailabilityDetailsApiCallId: string = "";
  deleteAvailabilityApiCallId: string = "";
  deleteJointApiCallId: string = "";
  cancelWaitlistApiCallId: string = "";
  cancelWaitlistedApiCallId: string ="";
  cancelWaitApiCallId: string = "";
  fetchChecklistItemsApiCallId: string = "";
  checklistDataApiCallId: string = "";
  dataFetchtApiCallId: string = "";
  testCrosstApiCallId: string = "";
  CancelApiApiCallId: string = "";
  deleteJointWaitlistApiCallId: string = "";
  editAvailabilityDetailsApiCallId: string = "";
  editDetailsyDetailsApiCallId: string = "";
  CheckUpdateApiCallId: string = "";
  unCheckTheChecklistApiCallId: string = "";
  buttonRef: any
  getCategoryApiCallId: string = "";
  getAirPortsForFilterApiCallId: string = "";
  getCertificateApiCallId: string = "";
  getClassApiCallId: string = "";
  getAircrafttypeApiCallId: string = "";
  fetchSingleAvailibilityForDpeApiCallId: string = "";
  fetchTestDetailsForApplicantApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.buttonRef = createRef();
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      selectedTime: 0,
      selectedDate: moment().format("YYYY-MM-DD"),
      timeSlots: [],
      serviceProviderId: "",
      serviceProviderSchedule: "",
      availability:[],
      filteredData:[],
      filteredData1:[],
      checkDes:[],
      joinWaitlistRes:{},
      carList:[],
      chatres:'',
      canceljoinWaitlist:{},
      all_dpe_account_count:'',
      filteredAccounts: [],
      showAvailableOnly: null,
      dpe_with_availability_count:'',
      details: {},
      token: null,
      date: new Date(),
      view: "month",
      open:false,
    availabilityDate: new Date() as any,
    isCalenderOpen:false,
    isSelectAirportOpen:false,
    isSelectTestTypeOpen:false,
    isNotifiyAll:false,
    timeSlot: [{ starttime: "10:00", endtime: "11:00" }],
    selectedTimeSlotForEdit: { starttime: "10:00", endtime: "11:00" },
    timeZones: [
      "Eastern Standard Time (EST)",
      "Central Standard Time (CST)",
      "Mountain Standard Time (MST)",
      "Pacific Standard Time (PST)",
      "Alaska Standard Time (AKST)",
      "Hawaii-Aleutian Standard Time (HST)",
    ],
    selectedTimeZone: "Eastern Standard Time (EST)",
    showtimeZoneOverlay: false,
    currentDate:new Date(),
    isSetDetailsOpen: false,
    isNotifyContainerOpen:false,
    allAirports: [],
    userSelectedAirports: [],
    allTests: [],
    paymentRes:[],
    userSelectedTests: [],
    allMembers: NotifyUsers,
    shouldNotifyAlluser:true,
    alertType: 'success'  as AlertType,
    alertMsg: '',

    isAlert: false,
    isViewAvailabiliDialogOpen: false,
    selectedAvailability:{airports:[], availability:null, designations:[]},
    isEditAvailabilityOpen:false,
    userSelectedMembers: [NotifyUsers[0], NotifyUsers[1]],
    deleteAvailabilityDialogOpen: false,
    openDialog:false,
    startDate: moment().startOf("month").toDate(), // Start of the current month
    endDate: moment().endOf("month").toDate(), // End of the current month
    events: [],
    availabilityId: 0,
    editselectedAirports: [],
    editSelectedTests: [],
    isFiltersDialogOpen: false,
    anchorEl: null,
    isDpePopupOpen: false,
    anchorElDpe: null,

    isTestPopupOpen: false,
    isAircraftPopupOpen: false,
    isAirportPopupOpen: false,
    isGradePopupOpen: false,
    isCategoryPopupOpen: false,
    isClassPopupOpen: false,
    allGradeData: [],
    filteredGradeData: [],
    allCategoryData: [],
    filteredCategoryData: [],
    allClassData: [],
    filterClassData: [],
    allAircrafts: [],
    filteredAircrafts:  [],
    searchQuery: "",
    allAirportsForFilter: [],
    filteredAirports: [],
    searchQueryAirport: "",
    isResetEnabled: false,
    todayDate: moment().format("YYYY-MM-DD"),
    initialFilters: {
      aircrafts: [],
      airports: [],
      categories: [],
      classes: [],
      grades: [],
    },
    userRole: "",
    selectedd: [], // Stores selected user IDs
    selectAll: false,
    isViewApplicantsAvailabilityDialogOpen: false,
    isTermsChecked: false,
    isDpeTestTypeOpen: false,
    dpeAllTest: [],
    depSelectedTests: { id: -1, service_subcategory_id: -1, service_subcategory_name: "" },
    isDpeAirportSelectOpen: false,
    dpeAllAirport: [],
    depSelectedAirport: { id: -1, service_subcategory_id: -1, service_subcategory_name: "" },
    dpeAvailabilityDate: "",
    dpeTimeslot: "",
    dpeTimezone:"",
    dpe_username:"",
    isLoading: false,
    dpeAvailabilityStatus: "",
    testDetailsData: {designation: "", accepted_payment_methods:"", exam_cost:"", instructions:"", pdf_files:[]},
    isPaymentDialogOpen: false,
    cardDetails: {id:"", cardHolderName:"", cardNumber:"", cvv:"", expiryDate:""},
    cardNumberError: "",
    errCardHolderName:"",
    errExpiryDate:"",
    errorInCardCVV:"",
    isRememberCardChecked: true,
    savedCard: [{id:"1", cardHolderName:"Test Test", cardNumber:"5555555555554444", cvv:"123", expiryDate:"15/14", isCardSelected: true, selectedCardOpen: false},
       { id:"2",cardHolderName:"Test Test", cardNumber:"4012888888881881", cvv:"123", expiryDate:"15/14", isCardSelected: true, selectedCardOpen: false}],
    userSelectedPaymentCard: { id:"2",cardHolderName:"Test Test", cardNumber:"1234 5678 9101 5080", cvv:"123", expiryDate:"15/14", isCardSelected: true, selectedCardOpen: false},
    isNewCardClicked: false,
    isPaymentSuccessDialogOpen: false,
    isPaymentFailedDialogOpen: false,
    availabilityEdited: false,
    openSections: {
      training: false,
      instruction: false,
      flight: false
    },
    trainingChecklist: [],
  instructionsChecklist: [],
  cardholderName: "",
  flightChecklist: [],
    selectedItems: {},
    selectedItems1: {},
    selectedItems2: {},
    showBox: true, 
    showBox1: false,
    cardType:"",
    joinedWaitlist: [],
    selectedMonth: new Date(), // Default to the current month
    openEdit: false,
    openDialogEdit: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
      this.getServiceProviderDetails({
        setApiCallId: "serviceProviderScheduleApiId",
        serviceProviderId: this.state.serviceProviderId,
        availableDate: this.state.selectedDate,
        token,
      });
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.success && responseJson.message== "Payment successfully!") {
        this.setState({  isPaymentSuccessDialogOpen: true , isPaymentDialogOpen: false}, () => {
            console.log("Success Dialog State:", this.state.isPaymentSuccessDialogOpen);
            this.forceUpdate();  
        });
    } 
    if (apiRequestCallId === this.payDetailsApiCallId) {
           this.setState({ carList: responseJson }, () => {
console.log('carList',this.state.carList)        
      });

    }
    if (apiRequestCallId === this.chatApiPostApiCallId) {
    if(responseJson)
  this.props.navigation.navigate('Messages')

    }

      if (apiRequestCallId === this.availabilityApiCallId) {
        this.setState({ availability: responseJson }, () => {
          this.transformEvents(); 
        });

      }

    
      if (apiRequestCallId === this.filterGetApiCallId) {
        this.setState({ 
            filteredData: responseJson.data.accounts, // Ensure this is the accounts array
            all_dpe_account_count: responseJson.data.all_dpe_account_count,
            dpe_with_availability_count: responseJson.data.dpe_with_availability_count,
            filteredAccounts: responseJson.data.accounts,
        });
    }
  

      if (apiRequestCallId === this.availabilityFilterApplicantApiCallId) {
        this.setState({ availability: responseJson }, () => {
          console.log('availabilityavailability',this.state.availability)
          this.transformEvents(); 
        });
      }

      if (apiRequestCallId === this.availabilityApplicantApiCallId) {
        this.setState({ availability: responseJson }, () => {
          this.transformEvents(); 
          console.log("availabilityavailability",this.state.availability)
        });
        
      }

  
    
      if (apiRequestCallId === this.joinWaitlistApiCallId) {
        const { dpe_account_id } = responseJson?.data?.waiting_list || {}; // Extract dpe_account_id safely
      
        if (dpe_account_id) {
          this.setState((prevState) => ({
            joinWaitlistRes: {
              ...prevState.joinWaitlistRes, // Preserve existing state
              [dpe_account_id]: responseJson?.data?.waiting_list?.number_in_wait_list, // Update only the clicked item
            },
          }));
        }
      }
       
     
      
      if (apiRequestCallId === this.deleteJointWaitlistApiCallId) {
        this.setState({ canceljoinWaitlist: responseJson });
      }
     

this.manageCheckResponce(apiRequestCallId, responseJson)
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson && responseJson.errors) {
        this.setState({ serviceProviderSchedule: [] });

        if (typeof responseJson.errors === "string") {
          this.showAlert("", responseJson.errors);
        } else {
          this.parseApiErrorResponse(responseJson);
          this.parseApiCatchErrorResponse(errorReponse);
        }
      } else if (apiRequestCallId != null) {
         this.handleApiResponse(apiRequestCallId, responseJson)
      }
    } else if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      runEngine.debugLog("Availability Message Received", message);
      const serviceProviderIdMessage = message.getData(
        getName(MessageEnum.ServiceProviderIdMessage)
      );
      if (serviceProviderIdMessage) {
        this.setState({
          serviceProviderId: serviceProviderIdMessage,
        });
      }

      const CalendarProviderDetailsApiMessage = message.getData(
        getName(MessageEnum.CalendarProviderDetailsApiMessage)
      );
      if (CalendarProviderDetailsApiMessage) {
        this.getServiceProviderDetails({
          ...CalendarProviderDetailsApiMessage,
          setApiCallId: "serviceProviderDetailApiId",
        });
        return;
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    // this.filterGet()
    this.payDetails()
    this.fetchChecklistItems()
    this.checklistData()
    this.cancelWait();
    this.cancelWaitlist();
    this.deleteJointWaitList1();
    this.getToken();
    this.getAirportsRequest()
    this.getAllSelectedTestsByDPE()
    this.getCertificateApi()
    this.getCategoryApi()
    this.getAirPortClassApi()
    this.getAircrafttypeApi()
    this.getAirPortsApi()
    this.availabilityfilterCal()
    const currentDate = new Date(); // Get the current date
    this.calculateMonthDates(currentDate); 
    const role = localStorage.getItem('role');
    this.setState({userRole: role as string, cardDetails: this.state.savedCard[0]})

    this.handleBackToViewAvailability()
    this.handleGetTestDetailsData()
    this.checkIsEditAirportsViewOpen()
    this.checkIsEditAirportsViewOpenAfterEdit()
    const showBox = localStorage.getItem("showBox1");
    this.handleCloseDialogEdit()
    if (showBox === "true") {
      this.setState({ showBox1: true }); 
    } else {
      this.setState({ showBox1: false }); 
    }


    const selectedItems = JSON.parse(localStorage.getItem("selectedItems") || "{}");
    const selectedItems1 = JSON.parse(localStorage.getItem("selectedItems1") || "{}");
    const selectedItems2 = JSON.parse(localStorage.getItem("selectedItems2") || "{}");
  
    this.setState({
      selectedItems,
      selectedItems1,
      selectedItems2,
    });


    const { dpeAllAirport, dpeAllTest } = this.state;

    const selectedAirport = localStorage.getItem('selectedAirport');
    const selectedTest = localStorage.getItem('selectedTest');
    
    if (!selectedAirport && dpeAllAirport.length === 1) {
      const defaultAirport = dpeAllAirport[0];
      localStorage.setItem('selectedAirport', JSON.stringify(defaultAirport.service_subcategory_id));
      this.setState({ depSelectedAirport: defaultAirport });
    } else if (selectedAirport !== null) { // Explicitly check for null
      const storedAirportId = JSON.parse(selectedAirport); 
      const storedAirport = dpeAllAirport.find((item: any) => item.service_subcategory_id === storedAirportId);
      if (storedAirport) this.setState({ depSelectedAirport: storedAirport });
    }
    
    if (!selectedTest && dpeAllTest.length === 1) {
      const defaultTest = dpeAllTest[0];
      localStorage.setItem('selectedTest', JSON.stringify(defaultTest.service_subcategory_id));
      this.setState({ depSelectedTests: defaultTest });
    } else if (selectedTest !== null) { // Explicitly check for null
      const storedTestId = JSON.parse(selectedTest); 
      const storedTest = dpeAllTest.find((item: any) => item.service_subcategory_id === storedTestId);
      if (storedTest) this.setState({ depSelectedTests: storedTest });
    }
    
  
  }


  checkIsEditAirportsViewOpen(){
    const isEditView = localStorage.getItem("isEditAirportView")
    const isEditTestView = localStorage.getItem("isEditTestView")
    if(isEditView === "true" || isEditTestView === "true"){
      const selectedAirports = localStorage.getItem("selectedAirports")
      const selectedTests = localStorage.getItem("selectedTests")
      const parsedSelectedTest = selectedTests && selectedTests.length > 0 ? JSON.parse(selectedTests) : [];
      const parsedSelectedAirports = selectedAirports && selectedAirports.length > 0 ? JSON.parse(selectedAirports) : [];
      this.setState({isSetDetailsOpen: true, userSelectedAirports: [...parsedSelectedAirports], userSelectedTests: [...parsedSelectedTest]},)
    }
  }

  handleOpenEdit(){
    this.setState({openEdit: true})
  }

  handleCloseEdit(){
    this.setState({openEdit: false})
  }

  checkIsEditAirportsViewOpenAfterEdit(){
    const availabilityId = localStorage.getItem("availabilityId")
    this.fetchAvailabilityDetails(availabilityId as any)
  }

  calculateMonthDates = (date: Date) => {

    const startDate = moment(date).startOf("month").toDate();
    const endDate = moment(date).endOf("month").toDate();
    this.setState({ startDate, endDate }, () => {
      const role = localStorage.getItem('role');

      if (role === 'dpe') {
        this.availabilityGet(startDate, endDate);
      } else if (role === 'applicant') {
          this.availabilityApplicant(startDate, endDate);
      } else {
          console.log('Role not recognized');
      }
     
    });
  };

  manageCheckResponce(apiRequestCallId: string, responseJson: any) {
    if (apiRequestCallId === this.fetchChecklistItemsApiCallId) {
        const checklistData = responseJson?.data?.data || []; // Extract data array properly
        
        if (checklistData.length >= 27) {
            this.setState({
                checkDes: responseJson,
                trainingChecklist: checklistData.slice(0, 9),
                instructionsChecklist: checklistData.slice(9, 18),
                flightChecklist: checklistData.slice(18, 27),
            });
        } else {
            this.setState({
                checkDes: responseJson,
                trainingChecklist: [],
                instructionsChecklist: [],
                flightChecklist: [],
            });
        }
    }
}

  handleOpenDialogEdit(){
    this.setState({openDialogEdit: true})
  }

  handleCloseDialogEdit(){
    this.setState({openDialogEdit: false})
  }

  
  handleApiResponse(apiRequestCallId: string, responseJson: any) {
    const apiHandlers = {
      [this.serviceProviderScheduleApiId]: () => {
        this.setState({
          serviceProviderSchedule:  responseJson && responseJson.data ? responseJson.data : "",
        });
      },
      [this.getAirportsApiCallId]: () => {
        this.getAirportsSucessCallBack(responseJson);
      },
      [this.getAllSelectedTestsApiCallId]: () => {
        this.getTestsSucessCallBack(responseJson);
      },
      [this.saveAvailablityApiCallId]: () => {
        this.getSaveAvailabilitySucessCallBack(responseJson);
      },
      [this.editAvailabilityDetailsApiCallId]: () => {
        this.updateAvailabilitySuccessCallBack(responseJson);
      },
      [this.fetchAvailabilityDetailsApiCallId]: () => {
        this.getAvailabilitySucessCallBack(responseJson);
      },
      [this.deleteAvailabilityApiCallId]:() => {
        this.deleteAvailabilitySuccessCallBack(responseJson);
      },
      [this.getCategoryApiCallId]:() => {
        this.getCategorySucessCallBack(responseJson);
      },
      [this.getAirPortsForFilterApiCallId]:() => {
        this.getAirportsFiltersSucessCallBack(responseJson);
      },
      [this.getCertificateApiCallId]:() => {
        this.getCertificateSucessCallBack(responseJson);
      },
      [this.getClassApiCallId]:() => {
        this.getAirPortClassSucessCallBack(responseJson);
      },
      [this.getAircrafttypeApiCallId]:() => {
        this.getAircrafttypeSucessCallBack(responseJson);
      },
      [this.fetchSingleAvailibilityForDpeApiCallId]: () => {
        this.getAvailabilityForDpeSuccessCallBack(responseJson)
      },
      [this.fetchTestDetailsForApplicantApiCallId]: () => {
        this.getTestDetailsForApplicantSuccessCallBack(responseJson)
      },
      [this.serviceProviderDetailApiId]: () => {
        let msg = new Message(getName(MessageEnum.NavigationCalendarMessage));
        msg.addData(
          getName(MessageEnum.CalendarProviderDetailsResponseMessage),
          responseJson
        );
        this.send(msg);
        this.unsubscribeMessages();
      },
    };
  
    if (apiHandlers[apiRequestCallId]) {
      apiHandlers[apiRequestCallId]();
    }
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  onSelectDate = (selectedDateStr: string) => {
    const { details } = this.state;
    this.setState({
      selectedDate: selectedDateStr,
      timeSlots: [],
      selectedTime: 0,
    });
    this.getServiceProviderAvailability(selectedDateStr, details);
  };

  calendarProps = {
    minDate: moment(),
    onSelectDate: (selectedDate: string) => this.onSelectDate(selectedDate),
  };

  formatDate = (date: Date = new Date()) => {
    return moment(date).format("dddd, MMMM D, YYYY");
  };

  handleAllCheckIcon() {
    if (
      this.state.userSelectedAirports.length === this.state.allAirports.length 
      && this.state.userSelectedAirports.length > 0 
      && this.state.allAirports.length
    ) {
      return checkedBox;
    }
    return unCheckedBox;
  }


  handleAllCheckIconEdit() {
    if (
      this.state.editselectedAirports.length === this.state.allAirports.length 
      && this.state.editselectedAirports.length > 0 
      && this.state.allAirports.length
    ) {
      return checkedBox;
    }
    return unCheckedBox;
  }

  handleAllTestCheckIconEdit() {
    if (
      this.state.editSelectedTests.length === this.state.allTests.length 
      && this.state.editSelectedTests.length > 0 
      && this.state.allTests.length
    ) {
      return checkedBox;
    }
    return unCheckedBox;
  }

  onCloseNewAvailabilityDialog(){
    this.setState({ open: false })
    this.handleCancel()
    localStorage.removeItem("selectedAirports")
    localStorage.removeItem("selectedTests")
  }

  oncloseAlert = () => {
    this.setState({ isAlert: false });
  };

  getColorForNotifyUser() {
    if (this.state.userSelectedMembers.length > 0 && !this.state.isNotifyContainerOpen) {
      return "#0F172A";
    }
    return this.state.isNotifyContainerOpen ? "#0074BF" : "#475569";
  }


  toggleCalenderOpen() {
    this.setState({
      isCalenderOpen: !this.state.isCalenderOpen,
      showtimeZoneOverlay: false
    });
  }

  toggleShowtimeZoneOverlay() {
    this.setState({
      showtimeZoneOverlay: !this.state.showtimeZoneOverlay,
      isCalenderOpen: false
    });
  }

  toggleSelectAirport() {
    this.setState({
      isSelectAirportOpen: !this.state.isSelectAirportOpen,
      isSelectTestTypeOpen: false,
      isNotifyContainerOpen: false,
    });
  }

  toggleSelectTest() {
    this.setState({
      isSelectTestTypeOpen: !this.state.isSelectTestTypeOpen,
      isSelectAirportOpen: false,
      isNotifyContainerOpen: false,
    });
  }

  toggleDpeSelectTest() {
    this.setState({
      isDpeTestTypeOpen: !this.state.isDpeTestTypeOpen,
      isDpeAirportSelectOpen: false
    });
  }

  toggleDpeSelectAirport() {
    this.setState({
      isDpeAirportSelectOpen: !this.state.isDpeAirportSelectOpen,
      isDpeTestTypeOpen: false
    });
  }


  toggleNotifyUserContainer() {
    this.setState({
      isNotifyContainerOpen: !this.state.isNotifyContainerOpen,
      isSelectAirportOpen: false,
      isSelectTestTypeOpen: false,
    });
  }

  handleAddTimeSlot() {
    this.setState((prevState) => ({
      timeSlot: [
        ...prevState.timeSlot,
        { starttime: "10:00", endtime: "11:00" },
      ],
    }));
  }

  handleDelete(indexToDelete: number) {
    this.setState((prevState) => ({
      timeSlot: prevState.timeSlot.filter(
        (_, index) => index !== indexToDelete
      ),
    }));
  }
  handleClose = () => {
    this.setState({ showBox: false }); 
};
handleClose1 = () => {
  localStorage.setItem("showBox1", "false"); 

  this.setState({ showBox1: false, isFiltersDialogOpen: true });
};

  handleUpdateTimeSlot = (
    index: number,
    key: keyof S["timeSlot"][0],
    value: string
  ): void => {
    this.setState((prevState) => {
      const updatedTimeSlots = [...prevState.timeSlot];
      updatedTimeSlots[index][key] = value;
      return { timeSlot: updatedTimeSlots };
    });
  };

  toggleSelectAllAirports() {
    const { userSelectedAirports, allAirports } = this.state;

    if (userSelectedAirports.length === allAirports.length) {
      this.setState({ userSelectedAirports: [] });
    } else {
      this.setState({ userSelectedAirports: allAirports });
    }
  }

  toggleSelectAllAirportsEdit() {
    const { editselectedAirports, allAirports } = this.state;

    if (editselectedAirports.length === allAirports.length) {
      this.setState({ editselectedAirports: [] });
    } else {
      this.setState({ editselectedAirports: allAirports });
    }
  }

  toggleSelectAllTestsEdit() {
    const { editSelectedTests, allTests } = this.state;

    if (editSelectedTests.length === allTests.length) {
      this.setState({ editSelectedTests: [] });
    } else {
      this.setState({ editSelectedTests: allTests });
    }
  }

  handleCloseDialog(){
    this.setState({openDialog: false})
  }

  handleOpenDialog(){
    this.setState({openDialog: true})
  }

  toggleSelectAllTests() {
    const { userSelectedTests, allTests } = this.state;

    if (userSelectedTests.length === allTests.length) {
      this.setState({ userSelectedTests: [] });
    } else {
      this.setState({ userSelectedTests: allTests });
    }
  }

  toggleSelectAllMembers() {
    const { userSelectedMembers, allMembers } = this.state;

    if (userSelectedMembers.length === allMembers.length) {
      this.setState({ userSelectedMembers: [] });
    } else {
      this.setState({ userSelectedMembers: allMembers });
    }
  }


  handleAirportCheckbox(serviceSubcategoryId: number) {
    const isChecked = this.state.userSelectedAirports.some(
      (airport) => airport.service_subcategory_id === serviceSubcategoryId
    );

    return isChecked;
  }

  handleAirportCheckboxEdit(serviceSubcategoryId: number) {
    const isChecked = this.state.editselectedAirports.some(
      (airport) => airport.service_subcategory_id === serviceSubcategoryId
    );

    return isChecked;
  }
  handleSelectAirport1(item: any) {
    this.setState({ depSelectedAirport: item }, () => {
      localStorage.setItem('selectedAirport',item.service_subcategory_id);
    });
  }
  
  handleSelectAirport(item: any) {
    const { userSelectedAirports } = this.state;

    const index = userSelectedAirports.findIndex(
      (airport) =>
        airport.service_subcategory_id === item.service_subcategory_id
    );

    let updatedUserSelectedAirports;
    if (index === -1) {
      updatedUserSelectedAirports = [...userSelectedAirports, item];
    } else {
      updatedUserSelectedAirports = userSelectedAirports.filter(
        (airport) =>
          airport.service_subcategory_id !== item.service_subcategory_id
      );
    }

    this.setState({ userSelectedAirports: updatedUserSelectedAirports });
  }

  toggleSection = (section: "training" | "instruction" | "flight") => {
    this.setState((prevState) => ({
      openSections: {
        ...prevState.openSections,
        [section]: !prevState.openSections[section], // Only toggles the section
      },
    }));
  };
  toggleCheckbox = async (id: number) => {
    this.setState(
      (prevState) => {
        const updatedState = {
          selectedItems: {
            ...prevState.selectedItems,
            [id]: !prevState.selectedItems[id],
          },
        };
        localStorage.setItem("selectedItems", JSON.stringify(updatedState.selectedItems));
        return updatedState;
      },
      async () => {
        if (this.state.selectedItems[id]) {
          await this.CheckUpdate(id);
        } else {
          await this.unCheckTheCheckList(id);
        }
      }
    );
  };
  
  toggleCheckbox1 = (id: number) => {
    this.setState(
      (prevState) => {
        const updatedState = {
          selectedItems1: {
            ...prevState.selectedItems1,
            [id]: !prevState.selectedItems1[id],
          },
        };
        localStorage.setItem("selectedItems1", JSON.stringify(updatedState.selectedItems1));
        return updatedState;
      },
      async () => {
        if (this.state.selectedItems1[id]) {
          await this.CheckUpdate(id);
        } else {
          await this.unCheckTheCheckList(id);
        }
      }
    );
  };
  
  toggleCheckbox2 = (id: number) => {
    this.setState(
      (prevState) => {
        const updatedState = {
          selectedItems2: {
            ...prevState.selectedItems2,
            [id]: !prevState.selectedItems2[id],
          },
        };
        localStorage.setItem("selectedItems2", JSON.stringify(updatedState.selectedItems2));
        return updatedState;
      },
      async () => {
        if (this.state.selectedItems2[id]) {
          await this.CheckUpdate(id);
        } else {
          await this.unCheckTheCheckList(id);
        }
      }
    );
  };
  handleSelectAirportEdit(item: any) {
    const { editselectedAirports } = this.state;

    const index = editselectedAirports.findIndex(
      (airport) =>
        airport.service_subcategory_id === item.service_subcategory_id
    );

    let updatedUserSelectedAirports;
    if (index === -1) {
      updatedUserSelectedAirports = [...editselectedAirports, item];
    } else {
      updatedUserSelectedAirports = editselectedAirports.filter(
        (airport) =>
          airport.service_subcategory_id !== item.service_subcategory_id
      );
    }

    this.setState({ editselectedAirports: updatedUserSelectedAirports });
  }

  handleDeleteAvailability(){
    this.setState({deleteAvailabilityDialogOpen: false, isViewAvailabiliDialogOpen: false, isEditAvailabilityOpen: false}, ()=>{
      this.deleteAvailability()
    })
  }

  timePickerInputRefs: { [key: string]: HTMLInputElement | null } = {};
  formatSelectedTest = (values: any[]) => {
    if (values.length === 1) {
      return values[0].designation ?? values[0]?.service_subcategory_name; 
    }
    return `${values[0].designation ?? values[0]?.service_subcategory_name}, +${values.length - 1} more`;
  };

  formatSelectedAirports = (values: any[]) => {
    if (values.length === 1) {
      return values[0]?.service_subcategory_name; 
    }
    if (values.length === 2) {
      return `${values[0]?.service_subcategory_name}, ${values[1].service_subcategory_name}`; 
    }
    if (values.length === 3) {
      return `${values[0]?.service_subcategory_name}, ${values[1].service_subcategory_name}, ${values[2].service_subcategory_name}`; 
    }
    return `${values[0]?.service_subcategory_name}, ${values[1].service_subcategory_name}, ${values[2].service_subcategory_name} +${values.length - 3} more`;
  };

  formatSelectedMembers = (values: any[]) => {
    if (values.length === 1) {
      return values[0].name; 
    }
    if (values.length === 2) {
      return `${values[0]?.name}, ${values[1].name}`; 
    }
    return `${values[0].name}, ${values[1].name} +${values.length - 2} more`;
  };

  handleSelectTests(item: any) {
    const { userSelectedTests } = this.state;

    const index = userSelectedTests.findIndex((tests) => tests.id === item.id);

    let updatedUserSelectedTests;
    if (index === -1) {
      updatedUserSelectedTests = [...userSelectedTests, item];
    } else {
      updatedUserSelectedTests = userSelectedTests.filter(
        (tests) => tests.id !== item.id
      );
    }

    this.setState({ userSelectedTests: updatedUserSelectedTests });
  }

  handleSelectMembers(item: any) {
    const { userSelectedMembers } = this.state;

    const index = userSelectedMembers.findIndex((member) => member.id === item.id);
    let updatedUserSelectedMembers;
    if (index === -1) {
      updatedUserSelectedMembers = [...userSelectedMembers, item];
    } else {
      updatedUserSelectedMembers = userSelectedMembers.filter(
        (member) => member.id !== item.id
      );
    }
    this.setState({ userSelectedMembers: updatedUserSelectedMembers });
  }

  convertToTimeString = (timestamp: string) => {
    const isTimeFormat = /^\d{2}:\d{2}$/.test(timestamp);
  
    if (isTimeFormat) {
      return timestamp;
    }
    return moment(timestamp).format("HH:mm");
  };

  shouldEnableCreateAvailablityBtn() {
    const { userSelectedAirports, userSelectedTests, shouldNotifyAlluser, userSelectedMembers } = this.state;
  
    return (
      userSelectedAirports.length >= 1 &&
      userSelectedTests.length >= 1 &&
      (shouldNotifyAlluser || userSelectedMembers.length >= 1)
    );
  }

  shouldEnableSaveEditedAvailablityBtn() {
    const { editSelectedTests, editselectedAirports, shouldNotifyAlluser, userSelectedMembers } = this.state;
  
    return (
      editselectedAirports.length >= 1 &&
      editSelectedTests.length >= 1 &&
      (shouldNotifyAlluser || userSelectedMembers.length >= 1)
    );
  }

  getColor = () => {
    if (this.state.userSelectedAirports.length > 0 && !this.state.isSelectAirportOpen) {
      return "#0F172A";
    }
    return this.state.isSelectAirportOpen ? "#0074BF" : "#475569";
  };

  getColorEdit = () => {
    if (this.state.editselectedAirports.length > 0 && !this.state.isSelectAirportOpen) {
      return "#0F172A";
    }
    return this.state.isSelectAirportOpen ? "#0074BF" : "#475569";
  };

  getSelectedTestColor = () => {
    if (this.state.userSelectedTests.length > 0 && !this.state.isSelectTestTypeOpen) {
      return "#0F172A";
    }
    return this.state.isSelectTestTypeOpen ? "#0074BF" : "#475569"
  };

  getSelectedTestColorEdit = () => {
    if (this.state.editSelectedTests.length > 0 && !this.state.isSelectTestTypeOpen) {
      return "#0F172A";
    }
    return this.state.isSelectTestTypeOpen ? "#0074BF" : "#475569"
  };

  getSelectedDpeTestColorEdit = () => {
    if (this.state.depSelectedTests  && !this.state.isDpeTestTypeOpen) {
      return "#475569";
    }
    return this.state.isDpeTestTypeOpen ? "#0074BF" : "#475569"
  };

  getSelectedDpeAirportolorEdit = () => {
    if (this.state.depSelectedAirport  && !this.state.isDpeAirportSelectOpen) {
      return "#475569";
    }
    return this.state.isDpeAirportSelectOpen ? "#0074BF" : "#475569"
  };

  handleCancelSetDetails(){
    this.setState({ isSetDetailsOpen: false, open: true, userSelectedAirports: [], userSelectedTests: [], shouldNotifyAlluser: true});
    localStorage.removeItem("isEditAirportView")
    localStorage.removeItem("isEditTestView")
  }

  getStartTime(){
    return this.state.timeSlot.map((slot) => this.convertToTimeString(slot.starttime))
  }

  getEndTime(){
    return this.state.timeSlot.map((slot) => this.convertToTimeString(slot.endtime))
  }

  getAvailablityDates() {
    return [
      moment(this.state.availabilityDate).format("YYYY-MM-DD")
    ];
  }

  getAirportForApiBody(){
    return this.state.userSelectedAirports.map((item) => ({
      service_subcategory_id: item.service_subcategory_id,
    }));
  }

  getTestsForApiBody(){
    return this.state.userSelectedTests.map((item) => ({
      service_subcategory_id: item.id,
    }));
  }

  getEditedAirportForApiBody(){
    return this.state.editselectedAirports.map((item) => ({
      service_subcategory_id: item.service_subcategory_id,
    }));
  }

  getEditedTestsForApiBody(){
    return this.state.editSelectedTests.map((item) => ({
      service_subcategory_id: item.id,
    }));
  }

  async handleSaveAvailability(){
    await this.saveAvailablity()
    localStorage.removeItem("isEditAirportView")
    localStorage.removeItem("isEditTestView")
  }

  getAirportsSucessCallBack = (response: any) => {
    this.setState({
        ...this.state,
        allAirports: response
    });
  }

  formatSelectedTestForView = (values: any[]) => {
    if (values.length === 1) {
      return values[0].service_subcategory_name; 
    }
    return `${values[0]?.service_subcategory_name}, +${values.length - 1} more`;
  };


  getAvailabilitySucessCallBack = (response: any) => {
    const availability = response.data.availability;
    let airports : any = []
    airports = response.data.airports
    let designations : any = []
    designations = response.data.designations
    const isEditViewForAirport = localStorage.getItem("isEditAirportViewForEdit")
    const isEditTestViewForTest = localStorage.getItem("isEditTestViewForEdit")

    if(isEditViewForAirport === "true" || isEditTestViewForTest === "true"){
      const selectedAirports = localStorage.getItem("selectedAirportsForEdit")        
      const selectedTests = localStorage.getItem("selectedTestsForEdit")
      designations = selectedTests && selectedTests.length > 0 ? JSON.parse(selectedTests) : [];
      airports = selectedAirports && selectedAirports.length > 0 ? JSON.parse(selectedAirports) : [];
      this.setState({isEditAvailabilityOpen: true,})
    }
    const updatedDesignations = designations?.map((des: any) => ({
      ...des,
      id: des.service_subcategory_id ?? des.id,
    }))
    if(availability){
      this.setState({
        editselectedAirports:airports,
        editSelectedTests: updatedDesignations,
        selectedTimeSlotForEdit:{ 
          starttime: this.state.selectedAvailability.availability?.start_time ?? availability.timeslots[0].from,
          endtime: this.state.selectedAvailability.availability?.end_time ?? availability.timeslots[0].to,
        },
        availabilityDate: moment(availability.availability_date, "DD/MM/YYYY").format("dddd, MMMM D, YYYY"),
        selectedTimeZone: availability.time_zone,
        selectedAvailability:{
          airports,
          designations,
          availability:{
            id: availability.id,
            status: availability.status,
            availability_date: this.formatDate(availability.availability_date),
            start_time: this.state.selectedAvailability.availability?.start_time ?? availability.timeslots[0].from,
            end_time: this.state.selectedAvailability.availability?.end_time ?? availability.timeslots[0].to,
            time_zone: availability.time_zone,
            availabilityAirport: airports[0]?.service_subcategory_name,
            availabilityTests: designations[0]?.service_subcategory_name,
            available_slots_count: availability.timeslots.length
          }
        }
      })
    }
  }

  handleTestDrodownColor() {
    const { isTestPopupOpen } = this.state
    if (isTestPopupOpen) {
      return {
        color: "#0074BF",
        marginBottom:"8px"
      }
    }
    return {
      color: "#475569",
      marginBottom:"8px"
    }
  }

  handleAircraftDrodownColor() {
    const { isAircraftPopupOpen } = this.state
    if (isAircraftPopupOpen) {
      return {
        color: "#0074BF",
        marginBottom:"8px"
      }
    }
    return {
      color: "#475569",
      marginBottom:"8px"
    }
  }

  handleTestDrodownIconColor() {
    const { isTestPopupOpen } = this.state
    if (isTestPopupOpen) {
      return "#0074BF"
    }
    return "#94A3B8"
  }

  handleDpeTestDrodownIconColor() {
    const { isDpeTestTypeOpen } = this.state
    if (isDpeTestTypeOpen) {
      return "#0074BF"
    }
    return "#94A3B8"
  }

  handleDpeAirportDrodownIconColor() {
    const { isDpeAirportSelectOpen } = this.state
    if (isDpeAirportSelectOpen) {
      return "#0074BF"
    }
    return "#94A3B8"
  }

  handleAircraftDrodownIconColor() {
    const { isAircraftPopupOpen } = this.state
    if (isAircraftPopupOpen) {
      return "#0074BF"
    }
    return "#94A3B8"
  }

  handleToggleTestPopup() {
    const { isTestPopupOpen } = this.state
    this.setState({ isTestPopupOpen: !isTestPopupOpen, isAircraftPopupOpen: false, isAirportPopupOpen: false })
  }

  handleToggleGradePopup() {
    const { isGradePopupOpen } = this.state
    this.setState({ isGradePopupOpen: !isGradePopupOpen })
  }

  handleToggleCategoryPopup() {
    const { isCategoryPopupOpen } = this.state
    this.setState({ isCategoryPopupOpen: !isCategoryPopupOpen})
  }

  handleToggleClassPopup() {
    const { isClassPopupOpen } = this.state
    this.setState({ isClassPopupOpen: !isClassPopupOpen})
  }

  handleGradeHeaderBg(){
    const {isGradePopupOpen} = this.state
    if(isGradePopupOpen){
      return {
        backgroundColor: "#F8FAFC"
      }
    }
    return {
      backgroundColor: "#fff"
    }
  }

  handleCategoryHeaderBg(){
    const {isCategoryPopupOpen} = this.state
    if(isCategoryPopupOpen){
      return {
        backgroundColor: "#F8FAFC"
      }
    }
    return {
      backgroundColor: "#fff"
    }
  }

  handleClassHeaderBg(){
    const {isClassPopupOpen} = this.state
    if(isClassPopupOpen){
      return {
        backgroundColor: "#F8FAFC"
      }
    }
    return {
      backgroundColor: "#fff"
    }
  }

  handleGradeIcon(){
    const {isGradePopupOpen} = this.state
    if(isGradePopupOpen){
      return remove
    }
    return add
  }

  handleCategoryIcon(){
    const {isCategoryPopupOpen} = this.state
    if(isCategoryPopupOpen){
      return remove
    }
    return add
  }

  handleClassIcon(){
    const {isClassPopupOpen} = this.state
    if(isClassPopupOpen){
      return remove
    }
    return add
  }

  toggleChipBackgroundColor(isSelected: boolean){
    if(isSelected){
      return "#0074BF"
    }
    return "#f1f5f9"
  }

  toggleChipColor(isSelected: boolean){
    if(isSelected){
      return "#ffffff"
    }
    return "#001c55"
  }

  toggleSelectAllGrades() {
    const { allGradeData } = this.state;
  
    const allSelected = this.areAllGradesSelected();
  
    const updatedGradeData = allGradeData.map((item: any) => ({ ...item, selected: !allSelected }));
  
    this.setState({
      filteredGradeData: updatedGradeData,
      allGradeData: updatedGradeData,
      isResetEnabled: allSelected ? true : false
    });
  }
  
  areAllGradesSelected() {
    const { filteredGradeData } = this.state;
    return filteredGradeData.every((item: { selected: any; }) => item.selected);
  }

  handleAllGradesCheckTextColor() {
    const allSelected = this.areAllGradesSelected();
    return allSelected ? "#fff": "#001c55" ;
  }

  handleAllGradesCheckChipColor() {
    const allSelected = this.areAllGradesSelected();
    return allSelected ? "#0074BF": "#f1f5f9" ;
  }

  toggleGradeSelection = (id: string) => {
    this.setState(prevState => {
      const updatedList = JSON.parse(JSON.stringify(prevState.allGradeData));

      const finalUpdatedList = updatedList.map((item: any) => {
        if (item.id === id) {
          return { ...item, selected: !item.selected };
        }
        return item;
      });
      const areFilteredListsEqual = isEqual(prevState.allGradeData, finalUpdatedList);
      return {
        allGradeData: finalUpdatedList,
        filteredGradeData: finalUpdatedList,
        isResetEnabled: !areFilteredListsEqual
      };
    });
   };

   toggleSelectAllCategories() {
    const { allCategoryData } = this.state;
  
    const allSelected = this.areAllCategoriesSelected(); 
  
    const updatedCategoryData = allCategoryData.map((item: any) => ({ ...item, selected: !allSelected }));
  
    this.setState({ 
      filteredCategoryData: updatedCategoryData,
      allCategoryData: updatedCategoryData ,
      isResetEnabled: allSelected ? true : false
    });
  }

  areAllCategoriesSelected() {
    const { filteredCategoryData } = this.state;
    return filteredCategoryData.every((item: { selected: any; }) => item.selected);
  }

  handleAllCategoriesCheckTextColor() {
    const allSelected = this.areAllCategoriesSelected();
    return allSelected ? "#fff": "#001c55" ;
  }

  handleAllCategoriesCheckChipColor() {
    const allSelected = this.areAllCategoriesSelected();
    return allSelected ? "#0074BF": "#f1f5f9" ;
  }

   toggleCategorySelection = (id: string) => {
    this.setState(prevState => {
      const updatedList = prevState.allCategoryData;
      const finalUpdatedList = updatedList.map((item: any) => {
        if (item.id === id) {
          return { ...item, selected: !item.selected };
        }
        return item;
      });
      const areFilteredListsEqual = isEqual(prevState.allCategoryData, finalUpdatedList);
      return {
        allCategoryData: finalUpdatedList,
        filteredCategoryData: finalUpdatedList,
        isResetEnabled: !areFilteredListsEqual
      };
    });
   };

   toggleSelectAllClass() {
    const { allClassData } = this.state;
  
    const allSelected = this.areAllClassesSelected();
  
    const updatedClassData = allClassData.map((item: any) => ({ ...item, selected: !allSelected }));
  
    this.setState({
      filterClassData: updatedClassData,
      allClassData: updatedClassData,
      isResetEnabled: allSelected ? true : false
    });
  }
  
  areAllClassesSelected() {
    const { filterClassData } = this.state;
    return filterClassData.every((item: { selected: any; }) => item.selected);
  }

  handleAllClassCheckTextColor() {
    const allSelected = this.areAllClassesSelected();
    return allSelected ? "#fff": "#001c55" ;
  }

  handleAllClassCheckChipColor() {
    const allSelected = this.areAllClassesSelected();
    return allSelected ? "#0074BF": "#f1f5f9" ;
  }

   toggleClassSelection = (id: string) => {
    this.setState(prevState => {
      const updatedList = JSON.parse(JSON.stringify(prevState.allClassData));

      const finalUpdatedList = updatedList.map((item: any) => {
        if (item.id === id) {
          return { ...item, selected: !item.selected };
        }
        return item;
      });
      const areFilteredListsEqual = isEqual(prevState.allClassData, finalUpdatedList);
      return {
        allClassData: finalUpdatedList,
        filterClassData: finalUpdatedList,
        isResetEnabled: !areFilteredListsEqual
      };
    });
   };

  handleToggleAircraftPopup() {
    const { isAircraftPopupOpen } = this.state
    this.setState({ isAircraftPopupOpen: !isAircraftPopupOpen, isAirportPopupOpen: false, isTestPopupOpen: false })
  }

  handleSearchAircraft = (text: string) => {
    this.setState(prevState => ({
      searchQuery: text,
      filteredAircrafts: prevState.allAircrafts
        .map(item => ({ ...item }))
        .filter(item => item.name.toLowerCase().includes(text.toLowerCase()))
    }));
  };

  toggleSelectAllAircraft() {
    const { allAircrafts } = this.state;
  
    const allSelected = this.areAllAircraftsSelected();
  
    const updatedAircrafts = allAircrafts.map(item => ({ ...item, selected: !allSelected }));
  
    this.setState({
      filteredAircrafts: updatedAircrafts,
      allAircrafts: updatedAircrafts,
      isResetEnabled: allSelected ? true : false
    });
  }
  
  areAllAircraftsSelected() {
    const { allAircrafts } = this.state;
    return allAircrafts.every(item => item.selected);
  }

  handleAllAircraftCheckIcon() {
    const allSelected = this.areAllAircraftsSelected();
    return allSelected ? checkedBox : unCheckedBox;
  }


  toggleAircraftSelection = (id: string) => {
    this.setState(prevState => {
      const updatedList = JSON.parse(JSON.stringify(prevState.allAircrafts));

      const finalUpdatedList = updatedList.map((item: any) => {
        if (item.id === id) {
          return { ...item, selected: !item.selected };
        }
        return item;
      });

      const updatedFilteredList = finalUpdatedList.filter((item: any) =>
        item.name.toLowerCase().includes(prevState.searchQuery.toLowerCase())
      );
      const areFilteredListsEqual = isEqual(prevState.filteredAircrafts, updatedFilteredList);
      return {
        allAircrafts: finalUpdatedList,
        filteredAircrafts: updatedFilteredList,
        isResetEnabled: !areFilteredListsEqual
      };
    });
  };

  resetFilter = () => {
    if(this.state.isResetEnabled === false) return
    this.setState(prevState => ({
      searchQuery: "",
      filteredAircrafts: [...prevState.initialFilters.aircrafts], 
      allAircrafts: [...prevState.initialFilters.aircrafts], 
      allAirportsForFilter: [...prevState.initialFilters.aircrafts], 
      searchQueryAirport: "",
      filteredAirports: [...prevState.initialFilters.airports], 
      filteredGradeData: [...prevState.initialFilters.grades], 
      filterClassData: [...prevState.initialFilters.classes], 
      filteredCategoryData: [...prevState.initialFilters.categories], 
      isResetEnabled: false,
    }));
  };

  handleToggleAirportPopup() {
    const { isAirportPopupOpen } = this.state
    this.setState({ isAirportPopupOpen: !isAirportPopupOpen, isAircraftPopupOpen: false, isTestPopupOpen: false })
  }

  handleAirportDrodownIconColor() {
    const { isAirportPopupOpen } = this.state
    if (isAirportPopupOpen) {
      return "#0074BF"
    }
    return "#94A3B8"
  }

  handleAirportDrodownColor() {
    const { isAirportPopupOpen } = this.state
    if (isAirportPopupOpen) {
      return {
        color: "#0074BF", 
        marginBottom:"8px"
      }
    }
    return {
      color: "#475569",
      marginBottom:"8px"
    }
  }

  handleSearchAirports = (text: string) => {
    this.setState(prevState => ({
      searchQueryAirport: text,
      filteredAirports: prevState.allAirportsForFilter
        .map(item => ({ ...item }))
        .filter(item => item.name.toLowerCase().includes(text.toLowerCase()))
    }));
  };

  toggleSelectAllAirport() {
    const { allAirportsForFilter } = this.state;
  
    const allSelected = this.areAllAirportsSelected();
  
    const updatedAirports = allAirportsForFilter.map(item => ({ ...item, selected: !allSelected }));
  
    this.setState({
      filteredAirports: updatedAirports,
      allAirportsForFilter: updatedAirports,
      isResetEnabled: allSelected ? true : false
    });
  }
  
  areAllAirportsSelected() {
    const { allAirportsForFilter } = this.state;
    return allAirportsForFilter.every(item => item.selected);
  }

  handleAllAirportCheckIcon() {
    const allSelected = this.areAllAirportsSelected();
    return allSelected ? checkedBox : unCheckedBox;
  }

  toggleAirportSelection = (id: string) => {

    this.setState(prevState => {
      const updatedList = JSON.parse(JSON.stringify(prevState.allAirportsForFilter));

      const finalUpdatedList = updatedList.map((item: any) => {
        if (item.id === id) {
          return { ...item, selected: !item.selected };
        }
        return item;
      });

      const updatedFilteredList = finalUpdatedList.filter((item: any) =>
        item.name.toLowerCase().includes(prevState.searchQueryAirport.toLowerCase())
      );
      const areFilteredListsEqual = isEqual(prevState.filteredAirports, updatedFilteredList);
      return {
        allAirportsForFilter: finalUpdatedList,
        filteredAirports: updatedFilteredList,
        isResetEnabled: !areFilteredListsEqual
      };
    });
   };

   getNavigation = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'ChecklList'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
     
    });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }

  getSaveAvailabilitySucessCallBack = (response: any) => {
    if(response.success){
      this.setState({
        ...this.state,
        isAlert: true,
        alertType:  "success",
        alertMsg: "Availability created successfully.",
        isSetDetailsOpen: false, 
        open: false 
      });
      this.handleReset()
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }else if(response.error){
      this.setState({
        ...this.state,
        isAlert: true,
        alertType: "error",
        alertMsg: response.message,
        isSetDetailsOpen: true, 
      });
    }
  }

  updateAvailabilitySuccessCallBack = (response: any) => {
    if(response && !response.error){
      const airports = response?.data?.airports
      const designations = response?.data?.designations
      const availability = response?.data?.availability;
      const updatedDesignations = designations?.map((des: any) => ({...des,id: des.service_subcategory_id,}))
      this.setState({
        isEditAvailabilityOpen:false,
        isViewAvailabiliDialogOpen:true,
        availabilityEdited: true,
        alertType: "success",
        alertMsg: "Availability updated successfully!",
        isAlert: true,
        selectedTimeSlotForEdit:{ 
          starttime: this.state.selectedAvailability.availability?.start_time ?? availability.timeslots[0].from,
          endtime: this.state.selectedAvailability.availability?.end_time ?? availability.timeslots[0].to,
        },
        editselectedAirports:airports,
        editSelectedTests: updatedDesignations,
        availabilityDate: moment(availability?.availability_date, "DD/MM/YYYY").format("dddd, MMMM D, YYYY"),
        selectedTimeZone: availability.time_zone,
        selectedAvailability:{
          designations,airports,
          availability:{
            id: availability.id,
            availabilityTests: designations[0]?.service_subcategory_name,
            available_slots_count: availability.timeslots.length,
            time_zone: availability.time_zone,
            availabilityAirport: airports[0]?.service_subcategory_name,
            status: availability.status,
            availability_date: this.formatDate(availability.availability_date),
            start_time: availability.timeslots[0].from,
            end_time: availability.timeslots[0].to,
          }
        }
      })
     localStorage.removeItem("isEditAirportViewForEdit")
     localStorage.removeItem("isEditTestViewForEdit")
    }else{
      this.setState({
        isEditAvailabilityOpen:true,
        alertType: "error",
        alertMsg: response.message,
        isAlert: true,
      })
    }
  }

  deleteAvailabilitySuccessCallBack = (response: any) => {
    if(response && !response.error){
      this.setState({
        ...this.state,
        deleteAvailabilityDialogOpen:false,
        isAlert: true,
        alertType: "success",
        alertMsg: "Availability deleted successfully!"
      });
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }else{
      this.setState({
        deleteAvailabilityDialogOpen:true,
        alertType: "error",
        alertMsg: response.message,
        isAlert: true,
      })
    }
  }

  formatTimeSlot(timeslot: { from: string; to: string }): string {
    const fromParts = timeslot.from.split(" ");
    const toParts = timeslot.to.split(" ");

    const fromTime = fromParts[0];
    const toTime = toParts[0];
    const period = toParts[1];

    return `${fromTime} - ${toTime} ${period}`;
  }

  handleReset(){
    this.setState({
      ...this.state,
      timeSlot: [{ starttime: "10:00", endtime: "11:00" }],
      selectedTimeZone: "Eastern Standard Time (EST)",
      userSelectedAirports: [],
      userSelectedTests: [],
      availabilityDate: new Date() as any,
      isAlert: true,
      isCalenderOpen: false,
      isNotifyContainerOpen: false,
      isSelectAirportOpen: false,
      isSelectTestTypeOpen: false,
      isSetDetailsOpen: false,
    });
  }

  handleCancel(){
      this.setState({
        ...this.state,
        timeSlot: [{ starttime: "10:00", endtime: "11:00" }],
        selectedTimeZone: "Eastern Standard Time (EST)",
        userSelectedAirports: [],
        userSelectedTests: [],
        availabilityDate: new Date() as any,
        isAlert: false,
        isCalenderOpen: false,
        isNotifyContainerOpen: false,
        isSelectAirportOpen: false,
        isSelectTestTypeOpen: false,
        isSetDetailsOpen: false,
        shouldNotifyAlluser: true,
        open: false,
        isEditAvailabilityOpen:false,
        alertMsg:"",
      });
      localStorage.removeItem("isEditAirportView")
      localStorage.removeItem("isEditTestView")
      localStorage.removeItem("selectedAirports")
      localStorage.removeItem("selectedTests")
  }

  handleCancelEdit(){
    this.setState({
      ...this.state,
      timeSlot: [{ starttime: "10:00", endtime: "11:00" }],
      selectedTimeZone: "Eastern Standard Time (EST)",
      userSelectedAirports: [],
      userSelectedTests: [],
      availabilityDate: new Date() as any,
      isAlert: false,
      isCalenderOpen: false,
      isNotifyContainerOpen: false,
      isSelectAirportOpen: false,
      isSelectTestTypeOpen: false,
      isSetDetailsOpen: false,
      shouldNotifyAlluser: true,
      open: false,
      isViewAvailabiliDialogOpen:true,
      isEditAvailabilityOpen:false,
      availabilityEdited:false,
      alertMsg:"",
    });
    localStorage.removeItem("isEditAirportViewForEdit")
    localStorage.removeItem("isEditTestViewForEdit")
    localStorage.removeItem("selectedAirportsForEdit")
    localStorage.removeItem("selectedTestsForEdit")
}

  getTestsSucessCallBack = (response: any) => {
    this.setState({
        ...this.state,
        allTests: response
    });
  }

  handleSelectTimezone(timezone:string, index:number){
      this.setState({
        selectedTimeZone: timezone,
        showtimeZoneOverlay: false,
      });
  }

  handleAllCheckIconforTests() {
    if (this.state.userSelectedTests.length === this.state.allTests.length) {
      return checkedBox;
    }
    return unCheckedBox;
  }

  handleAllCheckIconforMembers() {
    if (this.state.userSelectedMembers.length === 10 ) {
      this.setState({...this.state, userSelectedMembers: this.state.allMembers.slice(0,10)})
      return checkedBox;
    }else if (this.state.userSelectedMembers.length === this.state.allMembers.length ) {
      return checkedBox;
    }
    return unCheckedBox;
  }

  handleMemberCheckbox(id: number) {
    const isChecked = this.state.userSelectedMembers.some(
      (member) => member.id === id
    );

    return isChecked;
  }

  handleToggleSwitch(checked: boolean) {
    this.setState({ shouldNotifyAlluser: checked, isSelectAirportOpen:false, isSelectTestTypeOpen:false })
  }

  handleTestCheckbox(id: number) {
    const isChecked = this.state.userSelectedTests.some(
      (tests) => tests.id === id
    );

    return isChecked;
  }

  handleUpdateTimeSlotForEdit = (
    key: keyof S["selectedTimeSlotForEdit"],
    value: string
  ): void => {
    this.setState((prevState) => ({
      selectedTimeSlotForEdit: {
        ...prevState.selectedTimeSlotForEdit,
        [key]: value,
      },
    }));
  };

  getAvailabilityStarttime() {
    const startTime = this.state.selectedTimeSlotForEdit?.starttime
      ? moment(this.state.selectedTimeSlotForEdit.starttime, ["hh:mm A", "HH:mm"]).format("HH:mm")
      : this.state.selectedAvailability.availability?.start_time;
  
    return [startTime];
  }

  getAvailabilityEndtime() {
    const endTime = this.state.selectedTimeSlotForEdit?.endtime
      ? moment(this.state.selectedTimeSlotForEdit.endtime, ["hh:mm A", "HH:mm"]).format("HH:mm") 
      : this.state.selectedAvailability.availability?.end_time;
  
    return [endTime];
  }

  handleTestCheckboxforedit(id: number) {
    const isChecked = this.state.editSelectedTests.some(
      (tests) => tests.id === id
    );

    return isChecked;
  }

  handleSelectTestsforedit(item: any) {
    const { editSelectedTests } = this.state;
    const index = editSelectedTests.findIndex((tests) => tests.id === item.id);

    let updatedUserSelectedTests;
    if (index === -1) {
      updatedUserSelectedTests = [...editSelectedTests, item];
    } else {
      updatedUserSelectedTests = editSelectedTests.filter(
        (tests) => tests.id !== item.id
      );
    }

    this.setState({ editSelectedTests: updatedUserSelectedTests });
  }

  async getServiceProviderAvailability(selectedDateStr: any, profileData: any) {
    const token = this.state.token || "";
    if (profileData && profileData.id && selectedDateStr) {
      this.getServiceProviderDetails({
        setApiCallId: "serviceProviderScheduleApiId",
        serviceProviderId: profileData.id,
        availableDate: moment(selectedDateStr).format("YYYY/MM/DD"),
        token,
      });
    } else if (this.state.serviceProviderId && selectedDateStr) {
      this.getServiceProviderDetails({
        setApiCallId: "serviceProviderScheduleApiId",
        serviceProviderId: this.state.serviceProviderId,
        availableDate: moment(selectedDateStr).format("YYYY/MM/DD"),
        token,
      });
    }
  }

  async getServiceProviderDetails(dataObj: any) {
    const { setApiCallId, serviceProviderId, availableDate, token } = dataObj;

    const header = {
      "Content-Type": configJSON.applicationJsonApiContentType,
      token,
    };

    this.apiCall({
      setApiCallId,
      header,
      method: configJSON.getApiMethodType,
      endPoint: `${configJSON.serviceProviderAPiEndPoint}?availability_date=${availableDate}&service_provider_id=${serviceProviderId}`,
      body: null,
    });
    return true;
  }

  getAirportsRequest = () => {
    const token = localStorage.getItem('accessToken')
    const header = {
        "Content-Type": configJSON.validationApiContentType,
        token
    };
    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAirportsApiCallId = requestMessage.messageId;
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "bx_block_categories/dpe_airport_settings"
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async getAllSelectedTestsByDPE() {
    const token = localStorage.getItem('accessToken')
    const header = {
      "Content-Type": "application/json",
      token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllSelectedTestsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_categories/designation_instructions/designation_instructions_for_dpe"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async saveAvailablity() {
    if (this.shouldEnableCreateAvailablityBtn()) {
      const token = localStorage.getItem('accessToken')
      const header = {
        "Content-Type": "application/json",
        token
      };

      const data = {
        availabilities: [
          {
            start_time: this.getStartTime(),
            end_time: this.getEndTime(),
            availability_date: this.getAvailablityDates(),
            time_zone: this.state.selectedTimeZone,
            airports: this.getAirportForApiBody(),
            designations: this.getTestsForApiBody(),
          },
        ],
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.saveAvailablityApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "bx_block_appointment_management/availabilities/create_multiple"
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }


  async chatApiPost() {
    
      const token = localStorage.getItem('accessToken')
      const { serviceProviderId } = this.state;
      const header = {
        "Content-Type": "application/json",
        token
      };

      const data = {
        
          "user_id": serviceProviderId
      
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.chatApiPostApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "bx_block_chat/chats"
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    
  }

  async submitPayment(stripeToken: string, cardholderName: string) {
    const formattedStripeToken: string = stripeToken?.trim(); 

    const availabilityId = localStorage.getItem('applicantAvailabilityId')
    const testDetailsId = localStorage.getItem('testDetailsId')
    const selectedAirport = localStorage.getItem('selectedAirport')


    const token = localStorage.getItem('accessToken')
      const header = {
        "Content-Type": "application/json",
        token
      };
      const data = {
        stripe_token: formattedStripeToken,
        availability_id: availabilityId,
        designation_id:testDetailsId,
        airport_id: selectedAirport,
        cardholder_name: cardholderName
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.paymentApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "bx_block_stripe_integration/payments/submit_credit_card"
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    
  }

  async payDetails(){
    const token= localStorage.getItem('accessToken')

    const header = {
      "Content-Type": "application/json",
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.payDetailsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_stripe_integration/payments/list_saved_cards`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  async defaultPay(){
    const token= localStorage.getItem('accessToken')

    const header = {
      "Content-Type": "application/json",
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.defaultPayApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_stripe_integration//list_saved_cards`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  async joinWaitlistPay(dpe_account_id:any) {
    const token = localStorage.getItem('accessToken')
    const header = {
      "Content-Type": "application/json",
      token
    };

    const data = {
      
      "dpe_account_id": dpe_account_id
    
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.paymentsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_waitinglist/payments/payments"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

}


  handleJoinWaitlist = async (dpe_account_id:any) => {
    try {
      await this.joinWaitlist(dpe_account_id);

      this.setState((prevState) => ({
        joinedWaitlist: prevState.joinedWaitlist.includes(dpe_account_id)
          ? prevState.joinedWaitlist // Already joined, do nothing
          : [...prevState.joinedWaitlist, dpe_account_id], // Add ID to list
      }));
    } catch (error) {
      console.error("Error joining waitlist:", error);
    }
  };
  async joinWaitlist(dpe_account_id:any) {
      const token = localStorage.getItem('accessToken')
      const header = {
        "Content-Type": "application/json",
        token
      };

      const data = {
        
        "dpe_account_id": dpe_account_id
      
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.joinWaitlistApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "bx_block_waitinglist/waitinglists/join_wait_list"
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
  
  }






  apiCall = async (data: any) => {
    const { setApiCallId, header, endPoint, method, body } = data;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    if (setApiCallId === "serviceProviderDetailApiId") {
      this.serviceProviderDetailApiId = requestMessage.messageId;
    } else if (setApiCallId === "serviceProviderScheduleApiId") {
      this.serviceProviderScheduleApiId = requestMessage.messageId;
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  unsubscribeMessages = () => {
    runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
  };

  fetchSingleAvailibility = async (id: number) => {
    this.setState({isLoading: true})
    this.fetchSingleAvailibilityForDpeApiCallId = await this.scheduleAvailabilityApiCall({
        contentType: configJSON.exampleApiContentType,
        method: configJSON.getApiMethodType,
        endPoint: `bx_block_appointment_management/availabilities/${id}`
    })
  };

  fetchTestDetails = async (id: string) => {
    this.setState({isLoading: true})
    this.fetchTestDetailsForApplicantApiCallId = await this.scheduleAvailabilityApiCall({
        contentType: configJSON.exampleApiContentType,
        method: configJSON.getApiMethodType,
        endPoint: `bx_block_categories/designation_instructions/${id}`
    })
  };

  async fetchAvailabilityDetails(id:number){
    const token= localStorage.getItem('accessToken')

    const header = {
      "Content-Type": "application/json",
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchAvailabilityDetailsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_appointment_management/availabilities/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }


  availabilityGet = (startDate: Date|string, endDate: Date|string) => {
    let token= localStorage.getItem('accessToken')
    const headers = {
      "Content-Type": "application/json",
      token: token,
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.availabilityApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_appointment_management/availabilities?start_date=${startDate}&end_date=${endDate}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

   

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }


  availabilityfilterCal = () => {
    let token= localStorage.getItem('accessToken')
    const headers = {
      "Content-Type": "application/json",
      token: token,
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.availabilityfilterCalApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_appointment_management/availabilities?start_date`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

   

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  filterGet = () => {
    let token= localStorage.getItem('accessToken')
    const headers = {
      "Content-Type": "application/json",
      token: token,
    };
   
    const getSelectedIdsfilter = (data: any[]) =>
      data
        ?.filter((item: any) => item.selected === true) // Keep only selected = true
        .map((item: any) => item.id) // Extract IDs
        .join(","); // Convert to comma-separated string
  
    // Get selected IDs from different states
    const gradeIds = getSelectedIdsfilter(this.state.filteredGradeData);
    const categoryIds = getSelectedIdsfilter(this.state.filteredCategoryData);
    const classIds = getSelectedIdsfilter(this.state.filterClassData);
    const aircraftIds = getSelectedIdsfilter(this.state.filteredAircrafts);
    const airportIds = getSelectedIdsfilter(this.state.filteredAirports);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.filterGetApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_appointment_management/availabilities/get_dpe_users?grade_of_certificate=[${gradeIds}]&category=[${categoryIds}]&class=[${classIds}]&aircraft_type=[${aircraftIds}]&airport=[${airportIds}]`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

   

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  filterGet4 = () => {
    let token= localStorage.getItem('accessToken')
    const headers = {
      "Content-Type": "application/json",
      token: token,
    };
   
   
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.filterGetApiCallId4 = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_appointment_management/availabilities/get_filtered_availabilities`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

   

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  availabilityApplicant = (startDate: Date, endDate: Date) => {
    let token= localStorage.getItem('accessToken')
    const headers = {
      "Content-Type": "application/json",
      token: token,
    };
   
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.availabilityApplicantApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
`bx_block_appointment_management/availabilities/get_filtered_availabilities?grade_of_certificate=[]&category=[]&class=[]&aircraft_type=[]&all_aircraft_types=false&airport=[]&all_airports=true&start_date=${startDate}&end_date=${endDate}&dpe_ids_list=[]&all_dpe_users=true`    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

   

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }


  availabilityFilterApplicant = (startDate: Date, endDate: Date) => {
    let token = localStorage.getItem("accessToken");
    const headers = {
      "Content-Type": "application/json",
      token: token,
    };
  
    const formatDate = (date: Date) => {
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    };
  
    const formattedStartDate = formatDate(startDate);
    const formattedEndDate = formatDate(endDate);
  
    // Function to extract selected IDs
    const getSelectedIds = (data: any[]) =>
      data?.filter((item: any) => item.selected === true).map((item: any) => item.id).join(",");
  
    const gradeIds = getSelectedIds(this.state.filteredGradeData);
    const categoryIds = getSelectedIds(this.state.filteredCategoryData);
    const classIds = getSelectedIds(this.state.filterClassData);
    const aircraftIds = getSelectedIds(this.state.filteredAircrafts);
    const airportIds = getSelectedIds(this.state.filteredAirports);
  
    const selectedDpeIds = this.state.selectedd; // List of selected DPEs
    const allDpesSelected = selectedDpeIds.length === this.state.all_dpe_account_count;
  
    const selectedIds = {
      gradeIds: gradeIds,
      categoryIds: categoryIds,
      classIds: classIds,
      aircraftIds: aircraftIds,
      airportIds: airportIds,
    };
  
    localStorage.setItem("selectedIds", JSON.stringify(selectedIds));
  
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.availabilityFilterApplicantApiCallId = requestMessage.messageId;
  
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
  
    const dpeIdsListParam = allDpesSelected ? "" : `${selectedDpeIds.join(",")}`;
    const allDpeUsersParam = allDpesSelected ? "true" : "false";
  
    const allAircraftsSelected = this.areAllAircraftsSelected(); 
    const aircraftTypeParam = allAircraftsSelected ?`[]` : `[${aircraftIds}]`; 
    const allAircraftTypesParam = allAircraftsSelected ? "true" : "false"; 
  


    const allAirportsSelected = this.areAllAirportsSelected();
    const airportParam = allAirportsSelected ? `[]` : `[${airportIds}]`; // Empty array if all selected
    const allAirportsParam = allAirportsSelected ? "true" : "false"; // True if all selected
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_appointment_management/availabilities/get_filtered_availabilities?grade_of_certificate=[${gradeIds}]&category=[${categoryIds}]&class=[${classIds}]&aircraft_type=${aircraftTypeParam}&all_aircraft_types=${allAircraftTypesParam}&airport=[${airportParam}]&all_airports=${allAirportsParam}&start_date=${formattedStartDate}&end_date=${formattedEndDate}&dpe_ids_list=[${dpeIdsListParam}]&all_dpe_users=${allDpeUsersParam}`
    );
  
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  
  
  
  
  



  componentDidUpdate(prevProps: Props, prevState: any) {
    if (prevState.availability !== this.state.availability) {
      this.transformEvents();
      const currentDate = new Date(); 
      // this.calculateMonthDates(currentDate); 
    }
    if (prevState.selectedAvailability.availability !== this.state.selectedAvailability.availability) {
      this.setState({selectedAvailability: this.state.selectedAvailability})
    }
  }

  transformEvents = () => {
    if (!this.state.availability?.data?.availability) return;
    const transformedEvents = this.state.availability.data.availability.flatMap((avail: any) =>
      avail.data.attributes.time_slots.map((slot: any) => ({
        id: avail.data.id,
        title: `${slot.from}`, 
        start: moment(
          `${avail.data.attributes.availability_date} ${slot.from}`,
          "DD/MM/YYYY hh:mm A"
        ).toDate(),
        end: moment(
          `${avail.data.attributes.availability_date} ${slot.to}`,
          "DD/MM/YYYY hh:mm A"
        ).toDate(),
        allDay: false,
        booked_status: slot.booked_status, // Store the booked status
        user_name: avail.data.attributes.user_name,
        status_check: avail.data.attributes.status,
        airport: avail.data.attributes.airports?.[0]?.airport || "",      }))
    );
    
    this.setState({ events: transformedEvents });
  };

  getAirportContainerPlaceholderEdit() {
    const { editselectedAirports } = this.state;
    const length = editselectedAirports.length;

    if (length === 0) {
      return "Select airport(s)";
    }

    const airportNames = editselectedAirports
      .slice(0, 3)
      .map((airport) => airport.service_subcategory_name);

    if (length > 3) {
      return `${airportNames.join(", ")}, +${length - 3} more`;
    }

    return airportNames.join(", ");
  }

  getTestContainerPlaceholderEdit() {
    const { editSelectedTests } = this.state;
    const length = editSelectedTests.length;
    if (length === 0) {
      return "Select test type(s)";
    }

    const firstTestName = editSelectedTests[0]?.service_subcategory_name ?? editSelectedTests[0]?.designation;

    if (firstTestName?.length === 0 || firstTestName === undefined) {
      return "Select test type(s)"
    }

    if (length > 1) {
      return `${firstTestName}, +${length - 1} more`;
    }

    return firstTestName;
  }

  getDpeAirportSelectLabelEdit() {
    const { isDpeAirportSelectOpen, depSelectedAirport } = this.state;
    if (!isDpeAirportSelectOpen && depSelectedAirport.id !== -1) {
      return "AIRPORT SELECTED";
    }
    if(isDpeAirportSelectOpen) return
    return "AIRPORTS";
  }

  getDpeTestsSelectLabelEdit() {
    const { isDpeTestTypeOpen, depSelectedTests } = this.state;
    if (!isDpeTestTypeOpen && depSelectedTests.id !== -1) {
      return "TEST SELECTED";
    }
    if(isDpeTestTypeOpen) return
    return "TESTS";
  }

  handleTermsCheck(){
    this.setState({isTermsChecked: !this.state.isTermsChecked, isDpeTestTypeOpen: false, isDpeAirportSelectOpen: false})
  }

  getDpeAirportContainerPlaceholderEdit() {
    const { depSelectedAirport, isDpeAirportSelectOpen } = this.state;
  
    if (!depSelectedAirport || isDpeAirportSelectOpen || !depSelectedAirport.service_subcategory_name) {
      return "SELECT AIRPORT";
    }
  
    return depSelectedAirport.service_subcategory_name;
  }
  

  handleCancelBookAppointment() {
    localStorage.removeItem("applicantAvailabilityId")
    this.setState({isViewApplicantsAvailabilityDialogOpen: false, 
                  isDpeAirportSelectOpen: false, 
                  isDpeTestTypeOpen: false,
                  isTermsChecked: false,
                  depSelectedAirport: { id: -1, service_subcategory_id: -1, service_subcategory_name: "" },
                  depSelectedTests: { id: -1, service_subcategory_id: -1, service_subcategory_name: "" }})
  }

  getDpeTestContainerPlaceholderEdit() {
    const { depSelectedTests, isDpeTestTypeOpen } = this.state;
  
    if (!depSelectedTests || isDpeTestTypeOpen || !depSelectedTests.service_subcategory_name) {
      return "SELECT TEST";
    }
  
    return depSelectedTests.service_subcategory_name;
  }
  

  getDesignationPlaceholderWhenClosed() {
    const selectedDesignation = this.state.depSelectedTests
    if (selectedDesignation.id !== -1) {
      return selectedDesignation.service_subcategory_name
    } 

  return this.formatDesignation(this.state.dpeAllTest)
  }

  formatDesignation(designations: IDesignations[]) {
    const uniqueTests = [...new Set(designations.map(a => a.service_subcategory_name))];

    if (uniqueTests.length <= 1) {
      return uniqueTests.join(", ");
    } else {
      return uniqueTests.slice(0, 1).join(", ") + ", +" + (designations.length - 1) + " more";
    }
  }

  getAirportPlaceholderWhenClosed() {
    const selectedAirport = this.state.depSelectedAirport
    if (selectedAirport.id !== -1) {
      return selectedAirport.service_subcategory_name
    }

    return this.formatAirportList(this.state.dpeAllAirport)
  }

  formatAirportList(airports: IAirport[]) {
    const uniqueAirports = [...new Set(airports.map(a => a.service_subcategory_name))];

    if (uniqueAirports.length <= 3) {
      return uniqueAirports.join(", ");
    } else {
      return uniqueAirports.slice(0, 3).join(", ") + ", +" + (airports.length - 3) + " more";
    }
  }

  handleDpeTestCheckboxforedit(id: number) {
    const isChecked = this.state.depSelectedTests.service_subcategory_id === id;

    return isChecked;
  }

  handleDpeTestBackgroundColor(id: number) {
    const isChecked = this.state.depSelectedTests.service_subcategory_id === id;

    return isChecked ? "#E0F2F8" : "#fff";
  }

  handleDpeAirportBackgroundColor(id: number) {
    const isChecked = this.state.depSelectedAirport.service_subcategory_id === id;

    return isChecked ? "#E0F2F8" : "#fff";
  }


  handleDpeAirportCheckboxforedit(id: number) {
    const isChecked = this.state.depSelectedAirport.service_subcategory_id === id;

    return isChecked;
  }

  
  shouldProceedToBookingAppointment() {
    const { depSelectedAirport, depSelectedTests, isTermsChecked } = this.state
    if (depSelectedAirport.id !== -1 && depSelectedTests.id !== -1 && isTermsChecked) {
      return true
    }
    return false
  }

  proceedForBookingAppointment() {
    if(this.shouldProceedToBookingAppointment()){
      // localStorage.removeItem("applicantAvailabilityId")
      this.setState({isViewApplicantsAvailabilityDialogOpen: false, isPaymentDialogOpen: true})
    }
  }

  handleClickOnEvent(event: any){
    if(this.state.userRole === "dpe"){
      localStorage.setItem("availabilityId", event.id)
      this.handleOpenEventDetails(event)
    }else if(this.state.userRole === "applicant"){
      localStorage.setItem("applicantAvailabilityId", event.id)
      this.setState({isViewApplicantsAvailabilityDialogOpen: true,}, () => this.fetchSingleAvailibility(event.id))
    }
  }

  handleOpenEventDetails = (event: any) => {
    this.setState({ 
      availabilityId: event.id,
      selectedTimeSlotForEdit: {starttime: moment(event.start).format("hh:mm A"), endtime: moment(event.end).format("hh:mm A")},
      isViewAvailabiliDialogOpen: true,
      availabilityEdited:false,
      selectedAvailability:{ ...this.state.selectedAvailability, 
      availability: {
        ...this.state.selectedAvailability.availability,
        start_time: moment(event.start).format("hh:mm A"),
        end_time: moment(event.end).format("hh:mm A"),
    } as any} 
    });
    this.fetchAvailabilityDetails(event.id)
  }

  getCategoryApi = async () => {
    this.getCategoryApiCallId = await this.scheduleAvailabilityApiCall({
        contentType: configJSON.exampleApiContentType,
        method: configJSON.getApiMethodType,
        endPoint: configJSON.categoryEndPoint
    })
  };

  getCategorySucessCallBack = (response: Aircraft) => {
    if (Array.isArray(response) ) {
     const categoryOptions = response.map((category) => ({
         id: category.id.toString(), 
         name: category.name,      
         selected: true 
     }));
     this.setState((prevState) => ({
      ...prevState,
      allCategoryData: categoryOptions,
      filteredCategoryData: categoryOptions,
      initialFilters: {...prevState.initialFilters, categories: categoryOptions}
     }));
 }
 };

  getAirPortsApi = async () => {
    this.getAirPortsForFilterApiCallId = await this.scheduleAvailabilityApiCall({
        contentType: configJSON.exampleApiContentType,
        method: configJSON.getApiMethodType,
        endPoint: configJSON.getAirportListEndPoint
    })
  };

  getAirportsFiltersSucessCallBack = (response: Aircraft[]) => {
    const airportsOptions = response?.map((item) => ({
      id: item.id.toString(), 
      name: item.name,
      selected: true       
    }));
    this.setState((prevState) => ({
      ...prevState,
        allAirportsForFilter: airportsOptions,
        filteredAirports: airportsOptions,
        initialFilters: {...prevState.initialFilters, airports: airportsOptions}})
      );
}

  getCertificateApi = async () => {
    this.getCertificateApiCallId = await this.scheduleAvailabilityApiCall({
        contentType: configJSON.exampleApiContentType,
        method: configJSON.getApiMethodType,
        endPoint: configJSON.gradeOfCertificateEndPoint
    })
  };

  getCertificateSucessCallBack = (response: Aircraft) => {
    if (Array.isArray(response) ) {
     const gradeOptions = response.map((item) => ({
         id: item.id.toString(), 
         name: item.name,
         selected: true       
     }));
     this.setState((prevState) => ({
        ...prevState,
        allGradeData: gradeOptions,
        filteredGradeData: gradeOptions,
        initialFilters: {...prevState.initialFilters, grades: gradeOptions}
     }));
 }
  };

  getAirPortClassApi = async () => {
    this.getClassApiCallId = await this.scheduleAvailabilityApiCall({
        contentType: configJSON.exampleApiContentType,
        method: configJSON.getApiMethodType,
        endPoint: configJSON.airPortClassEndPoint
    })
  };

  getAirPortClassSucessCallBack = (response: Aircraft) => {
    if (Array.isArray(response) ) {
     const classOptions = response.map((classOptions) => ({
         id: classOptions.id.toString(), 
         name: classOptions.name,
         selected: true       
     }));
     this.setState((prevState) => ({
      ...prevState,
      allClassData: classOptions,
      filterClassData: classOptions,
      initialFilters: {...prevState.initialFilters, classes: classOptions}
     }));
 }
 };

  getAircrafttypeApi = async () => {
    this.getAircrafttypeApiCallId = await this.scheduleAvailabilityApiCall({
        contentType: configJSON.exampleApiContentType,
        method: configJSON.getApiMethodType,
        endPoint: configJSON.aircraftTypeEndPoint
    })
  };

  getAircrafttypeSucessCallBack = (response: Aircraft) => {
    if (Array.isArray(response) ) {
     const aircraftTypeOptions = response.map((aircraft) => ({
         id: aircraft.id.toString(), 
         name: aircraft.name,
         selected: true       
     }));
     this.setState((prevState) => ({
      ...prevState,
      allAircrafts: aircraftTypeOptions,
      filteredAircrafts: aircraftTypeOptions,
      initialFilters: {...prevState.initialFilters, aircrafts: aircraftTypeOptions}
     }));
    };
  }

  formatNumericDateToString(date?: string): string {
    if (!date) { return "";}

    const parts = date.split("/");

    if (parts.length !== 3) {
      return "";
    }

    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1;
    const year = parseInt(parts[2], 10);

    const dateObj = new Date(year, month, day);

    if (isNaN(dateObj.getTime())) {
      return "";
    }

    const options: Intl.DateTimeFormatOptions = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric"
    };

    return dateObj.toLocaleDateString("en-US", options);
  }

  handleBackToViewAvailability(){
    const applicantAvailabilityId = localStorage.getItem("applicantAvailabilityId")
    if(applicantAvailabilityId === "0" || !applicantAvailabilityId || this.state.userRole === "dpe") return
    this.setState({isViewApplicantsAvailabilityDialogOpen: true}, () => this.fetchSingleAvailibility(applicantAvailabilityId as any))
  }

  handleOnDetailsClick(item: any){
    this.setState({depSelectedTests: item})
    localStorage.setItem("testDetailsId", item.service_subcategory_id)
  }

  async handleNavigateToAirportSettings(){
    localStorage.setItem("isEditAirportView", "true")
    localStorage.setItem("selectedAirports", JSON.stringify(this.state.userSelectedAirports))
    localStorage.setItem("selectedTests", JSON.stringify(this.state.userSelectedTests))
    setTimeout(() => {
      this.props.navigation.navigate("SettingsAirports")
    },1000)
  }

  async handleNavigateToAirportSettingsForEdit(){
    localStorage.setItem("isEditAirportViewForEdit", "true")
    localStorage.setItem("selectedAirportsForEdit", JSON.stringify(this.state.editselectedAirports))
    localStorage.setItem("selectedTestsForEdit", JSON.stringify(this.state.editSelectedTests))
    setTimeout(() => {
      this.props.navigation.navigate("SettingsAirports")
    },1000)
  }

  handleNavigateToTestSettings(){
    localStorage.setItem("isEditTestView", "true")
    localStorage.setItem("selectedTests", JSON.stringify(this.state.userSelectedTests))
    localStorage.setItem("selectedAirports", JSON.stringify(this.state.userSelectedAirports))
    setTimeout(() => {
      this.props.navigation.navigate("SettingsTests")
    },1000)
  }

  handleNavigateToTestSettingsForEdit(){
    this.props.navigation.navigate("SettingsTests")
    localStorage.setItem("isEditTestViewForEdit", "true")
    localStorage.setItem("selectedTestsForEdit", JSON.stringify(this.state.editSelectedTests))
    localStorage.setItem("selectedAirportsForEdit", JSON.stringify(this.state.editselectedAirports))
    setTimeout(() => {
      this.props.navigation.navigate("SettingsTests")
    },1000)
  }

  handleGetTestDetailsData(){
    const testDetailsId = localStorage.getItem("testDetailsId")
    if(testDetailsId === "0" || !testDetailsId || this.state.userRole === "dpe") return
    this.fetchTestDetails(testDetailsId)
  }
  handleDone(){
    this.setState({isPaymentSuccessDialogOpen: false})
    window.location.reload();
  }
  getAvailabilityForDpeSuccessCallBack(responseJson: any) {
    if (responseJson && !responseJson.errors) {
      const { airports, designations, dpe_username} = responseJson.data
      const { availability_date, time_zone, timeslots, status,service_provider_id } = responseJson.data.availability
      this.setState({
        dpeTimeslot: this.formatTimeSlot(timeslots[0]),
        dpeTimezone: time_zone,
        dpeAllAirport: airports,
        dpe_username: dpe_username,
        dpeAllTest: designations,
        dpeAvailabilityDate: this.formatNumericDateToString(availability_date),
        dpeAvailabilityStatus: status,
        serviceProviderId: service_provider_id,
      })

      if (airports.length === 1) {
        this.setState({ depSelectedAirport: airports[0] })
      }

      if (designations.length === 1) {
        this.setState({ depSelectedTests: designations[0] })
      }
      } else {
        console.error("ERRRRTEST", responseJson.errors);
      }
  }

  getTestDetailsForApplicantSuccessCallBack(responseJson: any) {
    if (responseJson && !responseJson.errors) {
      const { designation, exam_cost, accepted_payment_methods, instructions, pdf_files } = responseJson
      this.setState({
        testDetailsData :{ 
          designation: designation, 
          exam_cost: exam_cost, 
          accepted_payment_methods:accepted_payment_methods, 
          instructions: instructions, pdf_files:pdf_files 
        }
      })
    } else {
      console.error("ERRRRTEST", responseJson.errors);
    }
  }

  handleDetailsClick(id: string){
    localStorage.setItem("testDetailsId", id);
    setTimeout(() => {
      window.location.href = "/calendar/test-details";
    }, 100);
  }

  detectCardType = (number: string) => {
    if (/^4/.test(number)) {
      return "Visa";
    } else if (/^5[1-5]/.test(number)) {
      return "MasterCard";
    }
    return "";
  };

  formatCardNumber = (number: string) => {
    return number.replace(/\D/g, "").replace(/(.{4})/g, "$1 ").trim();
  };

  renderCardImg(cardNumber: string){
    const cardType = this.detectCardType(cardNumber)
    if(cardType === "Visa"){
      return visacard
    }else {
      return payment
    }
  }

  formatExpiryValue = (value: string, previousValue: string): string => {
      value = value.replace(/[^0-9/]/g, ''); 

      if (value.length === 1 && value >= '2' && value <= '9') {
        value = '0' + value;
      }

      if (value.length === 2) {
        const month = parseInt(value, 10);
        if (month > 12) {
          value = '12';
        }
      }

      if (previousValue.length === 4 && value.length === 3) {
        value = value.slice(0, 2);
      }

      if (value.length === 3 && !value.includes('/')) {
        value = value.slice(0, 2) + '/' + value.slice(2);
      }

      return value.length > 5 ? value.slice(0, 5) : value;
  };

  handleCardExpire = (text: string, index: any) => {
    let value = text;
    let previousValue = this.state.cardDetails?.expiryDate; 
    value = value.replace(/[^0-9/]/g, '');

    const formattedValue = this.formatExpiryValue(text, previousValue);
    this.setState((prevState) => {
      const updatedSavedCards = [...prevState.savedCard];

      updatedSavedCards[index] = {
        ...updatedSavedCards[index], 
        expiryDate: formattedValue
      };
  
      return {
        ...prevState,
        savedCard: updatedSavedCards, 
        cardDetails: { ...this.state.cardDetails, expiryDate: value },
        userSelectedPaymentCard:{
          ...prevState.userSelectedPaymentCard, 
          expiryDate: formattedValue
        },
        errExpiryDate: '' 
      };
    },
   );

    if (formattedValue.length === 5) {
      this.validateFormattedValue(formattedValue)
    } else if (value.length < 5) {
      this.setState({ errExpiryDate: 'Invalid expiry date' });
    }
  };

  validateExpiryDate = (month: number, year: number): string => {
    const currentYear = new Date().getFullYear() % 100;
    const currentMonth = new Date().getMonth() + 1;
  
    if (year < currentYear || (year === currentYear && month < currentMonth)) {
      this.setState({ errExpiryDate: 'Card expired' });
    }
    return '';
  };  

  validateFormattedValue( value : string){
    const [month, year] = value.split('/').map(Number);
      if (month < 1 || month > 12) {
        this.setState({ errExpiryDate: 'Invalid month' });
      } else {
        this.validateExpiryDate(month, year)
      }
  }

  handleCloseViewDialog(){
    this.setState({ isViewAvailabiliDialogOpen: false }, () => {
      if (this.state.availabilityEdited) {
        window.location.reload();
      }
    })
  }

  handleCloseEditDialog(){
    localStorage.removeItem("isEditAirportViewForEdit")
    localStorage.removeItem("isEditTestViewForEdit")
    localStorage.removeItem("selectedAirportsForEdit")
    localStorage.removeItem("selectedTestsForEdit")
    this.setState({ isEditAvailabilityOpen: false , isViewAvailabiliDialogOpen: true})
  }

  getSaveButtonClass = () => {
    return this.shouldEnableSaveEditedAvailablityBtn() ? "setDetailsButton" : "saveButton";
  };

  getEditAvailabilityHandler = () => {
    return this.shouldEnableSaveEditedAvailablityBtn() ? () => this.editAvailabilityDetails() : undefined;
  };
  
  
  validateCardNumber(value: string){
    let err = ""
    let cardNumber = value.replace(/\D/g, ""); 
    cardNumber = cardNumber.substring(0, 16);
    const cardType = this.detectCardType(cardNumber);

    if (cardNumber.length === 0) {
      err = "Card number cannot be empty";
    } else if (cardNumber.length > 0 && cardNumber.length < 16) {
      err = "Card number must be 16 digits";
    } else if (!cardType && cardNumber.length === 16) {
      err = "Invalid card number";
    }

    this.setState({ cardType: cardType })
    return err
  }

  validateCardHolderName(value : string){
    if (!/^[A-Za-z\s]+$/.test(value)) {
     return "Cardholder name must contain only alphabets.";
    }
  }

  validateCvv(value: string){
    const sanitizedNumber = value.replace(/\D/g, "");
    if (sanitizedNumber.length > 4) {
      return;
    }

    this.setState({ cardDetails: {...this.state.cardDetails, cvv: sanitizedNumber}  });

    if (sanitizedNumber.length < 3) {
      this.setState({ errorInCardCVV: "CVV must be at least 3 digits" });
    } else {
      this.setState({ errorInCardCVV: "" });
    }
  }

  handleInputChange = (field: keyof SingleCardDetails, value: string, index: any) => {
    let formattedValue = value;
    let errCardHolderName = "";
    let err = ""
    if (field === "cardNumber") {
     err = this.validateCardNumber(formattedValue)
    }

    if (field === "cardHolderName") {
      errCardHolderName = this.validateCardHolderName(value) as string
    } 

    if(field === "cvv"){
      this.validateCvv(value)
    }
    this.setState((prevState) => {
      const updatedSavedCards = [...prevState.savedCard];

      updatedSavedCards[index] = {
        ...updatedSavedCards[index], 
        [field]: formattedValue
      };
  
      return {
        ...prevState,
        savedCard: updatedSavedCards, 
        cardDetails: {
          ...prevState.cardDetails, 
          [field]: formattedValue,
        },
        userSelectedPaymentCard:{
          ...prevState.userSelectedPaymentCard, 
          [field]: formattedValue,
        },
        cardNumberError: err,
        errCardHolderName: errCardHolderName,
      };
    });
  }

  getCardTypeImage() {
    if (this.state.cardType === "Visa") {
      return visacard 
    }
    else {
      return payment
    }
  }

  handleSelectaAndOpenCardDetails(index: number, item: SingleCardDetails){
    this.handleOnCardArrowClick(index)
    this.handleOnCardClick(item, index)
    this.setState({cardDetails: item})
  }

  handleAddNewCard(){
    const newCard = { id:"-1",cardHolderName:"", cardNumber:"", cvv:"", expiryDate:"", isCardSelected: true, selectedCardOpen: true}
    const updatedSavedCards = this.state.savedCard.map((card, i) => ({
      ...card,
      selectedCardOpen: false 
    }));
    this.handleOnCardClick(newCard, 0)
    this.setState((prevState) => ({
      ...prevState,
      savedCard: [...updatedSavedCards, newCard],
      isNewCardClicked: true,
    }))
  }

  handleCancelAddnewCard(id: string){
    if(id){
      const updatedCard = this.state.savedCard.filter((card) => card.id !== id)
      this.setState({savedCard:[...updatedCard], isNewCardClicked: false})
    }
  }
  
  formatCVV = (value: string) => {
    return value.replace(/\D/g, "").slice(0, 4);
  };

  handleRememberCardCheck(){
    this.setState({isRememberCardChecked: !this.state.isRememberCardChecked})
  }

  formatCardNumberForDisplay = (cardNumber: string) => {
    const cleanedCardNumber = cardNumber.replace(/\D/g, "");
    const visiblePart = cleanedCardNumber.slice(-4); 
    const maskedPart = "**** **** **** "; 
  
    return `${maskedPart}${visiblePart}`;
  };

  handleSelectedCardRadio(id: string) {
    const isChecked = this.state.userSelectedPaymentCard.cardNumber === id;

    return isChecked;
  }

  handleOnCardClick = (item: any, index: any) => {
    const updatedSavedCards = this.state.savedCard.map((card, i) => ({
      ...card,
      isCardSelected: i === index ? !card.selectedCardOpen : false 
    }));
  
    this.setState({userSelectedPaymentCard: item, cardDetails: item, savedCard: updatedSavedCards});
  };

  handleOnCardArrowClick = (index: number) => {
    const updatedSavedCards = this.state.savedCard.map((card, i) => ({
      ...card,
      selectedCardOpen: i === index ? !card.selectedCardOpen : false 
    }));
  
    this.setState({
      savedCard: updatedSavedCards, 
    });
  };

  handleSelectedCardArrowIconColor(isOpen: boolean) {
    if (isOpen) {
      return "#0074BF"
    }
    return "#94A3B8"
  }

  shouldProceedToPayment() {
    const { cardHolderName, cardNumber, cvv, expiryDate, } = this.state.cardDetails
    if (!!cardHolderName && !!cvv && !!cardNumber && !!expiryDate) {
      return true
    }
    return false
  }

  proceedForDeletePaymentCard(id: string) {
    if(id){
      const updatedCard = this.state.savedCard.filter((card) => card.id !== id)
      this.setState({savedCard:[...updatedCard]})
    }
  }

  handleCheckListClick(){
    setTimeout(() => {
      window.location.href = "/calendar/Check-List";
    }, 100);
  }

  scheduleAvailabilityApiCall = async (valueData: {
    contentType?: string;
    method?: string;
    endPoint?: string;
    body?: {};
    apiType?: string;
    type?: string;
  }) => {
    const token = (localStorage.getItem('accessToken'))
    let { contentType, method, endPoint, body, apiType } = valueData;
    let header = {
        "Content-Type": contentType,
        Token: token
    };
    let accountSetupRequestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    accountSetupRequestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        method
    );
    accountSetupRequestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endPoint
    );
    body &&
    accountSetupRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            apiType === "" ? JSON.stringify(body) : body
        );
        accountSetupRequestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    runEngine.sendMessage(accountSetupRequestMessage.id, accountSetupRequestMessage);
    return accountSetupRequestMessage.messageId;
  };
  
  async editAvailabilityDetails(){
    const token = localStorage.getItem('accessToken');
    const header = {
      "Content-Type": "application/json",
      token: token,
    };

    const data = {
        start_time: this.getAvailabilityStarttime(),
        end_time: this.getAvailabilityEndtime(),
        availability_date: moment(this.state.availabilityDate).format("DD/MM/YYYY"),
        time_zone: this.state.selectedTimeZone,
        airports: this.getEditedAirportForApiBody(),
        designations: this.getEditedTestsForApiBody(),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editAvailabilityDetailsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_appointment_management/availabilities/${this.state.availabilityId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  async editDetails(){
    const token = localStorage.getItem('accessToken');
    const header = {
      "Content-Type": "application/json",
      token: token,
    };

   

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editDetailsyDetailsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_appointment_management/availabilities`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  async CheckUpdate(id: number){
    const token = localStorage.getItem('accessToken');
    const header = {
      "Content-Type": "application/json",
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.CheckUpdateApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_tasks/tasks/${id}/check_item`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

   

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }


  async unCheckTheCheckList(id: number){
    const token = localStorage.getItem('accessToken');
    const header = {
      "Content-Type": "application/json",
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.unCheckTheChecklistApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_tasks/tasks/${id}/uncheck_item`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

   

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  async deleteAvailability(){

    const token = localStorage.getItem('accessToken');

    const header = {
      "Content-Type": "application/json",
      token: token,
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteAvailabilityApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_appointment_management/availabilities/${this.state.availabilityId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }




  handleRemoveFromWaitlist = async (userId: any) => {
    await this.deleteJointWaitList();
  
    this.setState((prevState) => ({
      joinedWaitlist: prevState.joinedWaitlist.filter((id: any) => id !== userId),
      joinWaitlistRes: {
        ...prevState.joinWaitlistRes,
        [userId]: undefined, // Reset only the canceled waitlist entry
      },
    }));
  };
  
  
  async deleteJointWaitList(){

    const token = localStorage.getItem('accessToken');

    const header = {
      "Content-Type": "application/json",
      token: token,
    };
    const waitlistId = this.state.joinWaitlistRes?.data?.waiting_list?.id;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteJointWaitlistApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_waitinglist/waitinglists/cancel_join_wait_list?id=${waitlistId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }





  async deleteJointWaitList1(){

    const token = localStorage.getItem('accessToken');

    const header = {
      "Content-Type": "application/json",
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteJointApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_waitinglist/waitinglists/cancel_join_wait_list`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


 
  async cancelWaitlist(){

    const token = localStorage.getItem('accessToken');

    const header = {
      "Content-Type": "application/json",
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.cancelWaitlistApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_waitinglist/edit`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async cancelWaitlisted(){

    const token = localStorage.getItem('accessToken');

    const header = {
      "Content-Type": "application/json",
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.cancelWaitlistedApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_waitinglist/waitingliststing`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async cancelWait(){

    const token = localStorage.getItem('accessToken');

    const header = {
      "Content-Type": "application/json",
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.cancelWaitApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_waitinglist/waitinglists/cancel`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }




  async fetchChecklistItems(){

    const token = localStorage.getItem('accessToken');

    const header = {
      "Content-Type": "application/json",
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchChecklistItemsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_tasks/tasks`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  async checklistData(){

    const token = localStorage.getItem('accessToken');

    const header = {
      "Content-Type": "application/json",
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.checklistDataApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_tasks/tasks/test`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleOpenDpePopup = (event:any) => {
    this.setState({ isDpePopupOpen: true, isFiltersDialogOpen: false});
    this.filterGet()
    localStorage.setItem("showBox1", "true"); 

  };


  handleCloseDpePopup = () => {
    localStorage.setItem("showBox1", "true"); // ✅ Store 'true' in localStorage
  
    this.setState({ isDpePopupOpen: false, showBox1: true }, () => {
      this.availabilityFilterApplicant(this.state.startDate, this.state.endDate);
      // window.location.reload();
    });
  };
  
  
  
  
  handleCloseDpePopup24 = () => {
    this.setState({ isFiltersDialogOpen: true,isDpePopupOpen:false,}) 
  };
  
   
    
  // Customizable Area End
}
