import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from "react-toastify";
interface RecordItem {
  title: string;
  question: string;
  answer: string[];
}
interface ErrorToken {
  token: string;
}

interface ErrorResponse {
  errors: ErrorToken[];
}
export interface Exam {
  account_id: number;
  username: string;
  availability_id: number;
  availability_date: string;
  time: string;
  when: string;
  airport: string | null;
  designation: string | null;
  checkride_outcome?: string | null; 
}

export interface ExamResponseData {
  upcoming_exams: Exam[];
  upcoming_exams_count: number;
  past_exams: Exam[];
  past_exams_count: number;
  total_items_count: number;
  checked_items_count: number;
}

export interface ExamApiResponse {
  success: boolean;
  message: string;
  data: ExamResponseData;
  error: string | null;
}

interface ChecklistResponse {
  success: boolean;
  message: string;
  data: {
    data: Task[];
  };
  error: string | null;
}

export interface Task {
  id: string;
  type: string;
  attributes: TaskAttributes;
}

interface TaskAttributes {
  id: number;
  account_id: number;
  title: string | null;
  description: string;
  is_checked: boolean;
  category_type: string | null;
  created_at: string; 
  updated_at: string; 
  assigned_to: string | null;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  records: Array<RecordItem>;
  statusModal: boolean;
  status: string[];
  reason: Array<string>;
  uploadFileSelected: File | null;
  selectStatus: boolean;
  CheckRideOutcome: boolean;
  checkRideGoModal: boolean;
  statusValue: string;
  isOptOutWorningMessage: boolean;
  examData: ExamResponseData;
  pastExamIndex: number;
  upcomingExamIndax: number;
  selectUpcoming: Exam;
  trainingChecklist: Task[];
  instructionsChecklist:Task [];
  flightChecklist: Task[];
  checklistRecord: Task[];
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start  
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationPropsMessage),
    ];

    this.state = {
      records: [
        {
          title: "Training Records",
          question: "Please make sure your logbook contains the following ground and flight training",
          answer: ["Received and logged flight training from 61.87(d).",
            "Received and logged flight training from 61.93(e)."]
        }, {
          title: "Instructor Endorsements",
          question: "Please make sure your logbook contains the following ground and flight training",
          answer: ["Received and logged flight training from 61.87(d).",
            "Received and logged flight training from 61.93(e)."]
        },
        {
          title: "Flight Experience",
          question: "Please make sure your logbook contains the following ground and flight training",
          answer: ["Received and logged flight training from 61.87(d).",
            "Received and logged flight training from 61.93(e)."]
        }],
      statusModal: false,
      status: ["I passed!", "Re-check required", "Checkride discontinued", "Checkride did not occur"],
      reason: ["I had a conflict", "Inclement weather", "DPE canceled"],
      uploadFileSelected: null,
      selectStatus: false,
      CheckRideOutcome: false,
      checkRideGoModal: false,
      statusValue: "",
      isOptOutWorningMessage: false,
      examData: {
        upcoming_exams: [],
        upcoming_exams_count: 0,
        past_exams: [],
        past_exams_count: 0,
        total_items_count: 0,
        checked_items_count: 0,
      },
      pastExamIndex: 0,
      upcomingExamIndax: 0,
      selectUpcoming: {
        account_id: 0,
        username: "",
        availability_id: 0,
        availability_date: "",
        time: "",
        when: "",
        airport: null,
        designation: null
      },
      trainingChecklist: [],
      instructionsChecklist: [],
      flightChecklist: [],
      checklistRecord: []
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.responseSucessCell(apiRequestCallId, responseJson)
      }
      else if (responseJson && responseJson.errors) {
        this.responseFailureCell(apiRequestCallId, responseJson)
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  upcomingAndPastExamApiCallId: string = "";
  getCheckListApiCallId: string = "";
  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  async componentDidMount() { 
    this.setState({ checkRideGoModal: true })
    this.upcomongAndPastExamApi()
    this.getChecklistApi()
    if (localStorage.getItem("isAuthenticated") === "true") {
      window.history.pushState(null, "", window.location.href);
      window.addEventListener("popstate", this.handleBack);
    }
  }

  async componentWillUnmount() {
    window.removeEventListener("popstate", this.handleBack);
  }

  handleBack = () => {
    window.history.pushState(null, "", window.location.href);
  };
  
  handleStatusOpenModal = (upcomingData: Exam) => {
    this.setState({ statusModal: true, selectUpcoming: upcomingData })
  }

  handleStatusCloseModal = () => {
    this.setState({ statusModal: false })
  }

  handleUploadFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event?.target?.files?.[0];
    if (file) {

      this.setState({
        uploadFileSelected: file,
      })
    }
  };

  handleSubmitStatu = () => {
    this.setState({ selectStatus: true })
  };

  backStatusOption = () => {
    this.setState({ selectStatus: false })
  }

  chackRideOutcomeOpenModal = (status: string) => {
    this.setState({ statusValue: status, CheckRideOutcome: true })
  }

  chackRideOutcomeCloseModal = () => {
    this.setState({ CheckRideOutcome: false, selectStatus: false })
  }

  checkRideGoCloseModal = () => {
    this.setState({ checkRideGoModal: false })
  }

  optOutWorningMessageOpenModal = () => {
    this.setState({ isOptOutWorningMessage: true, CheckRideOutcome: false })
  }

  optOutWorningMessageCloseModal = () => {
    this.setState({ isOptOutWorningMessage: false, selectStatus: false })
  }

  handleOption = (event: React.SyntheticEvent, statusValue: string) => {
    this.setState({ statusValue: statusValue })
  }
  convertToUpperCase = (value: string) => {
    return value?.toUpperCase()
  };

  navigateScreens = (pathName: string) => {
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), pathName);
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg);
  };

  landingPageApiCall = async (valueData: {
    contentType?: string;
    method?: string;
    endPoint?: string;
    body?: {};
    apiType?: string;
    type?: string;
  }) => {
    const token = await (localStorage.getItem('accessToken'))
    let { contentType, method, endPoint, body, apiType } = valueData;
    let header = {
      "Content-Type": contentType,
      Token: token
    };
    let landingPageRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    landingPageRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    landingPageRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    body &&
      landingPageRequestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        apiType === "" ? JSON.stringify(body) : body
      );
    landingPageRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(landingPageRequestMessage.id, landingPageRequestMessage);
    return landingPageRequestMessage.messageId;
  };

  responseSucessCell = async (apiRequestCallId: string, responseJson: ExamApiResponse & ChecklistResponse) => {
    if (apiRequestCallId === this.upcomingAndPastExamApiCallId) {
      this.upcomongExamSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.getCheckListApiCallId) {
      this.getChecklistApiSucessCallBack(responseJson);
    }
  };

  responseFailureCell = async (apiRequestCallId: string, responseJson: ErrorResponse) => {
    if (apiRequestCallId === this.upcomingAndPastExamApiCallId) {
      this.upcomongExamFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.getCheckListApiCallId) {
      this.getChecklistApiFailureCallBack(responseJson);
    }
  };

  getChecklistApi = async () => {
    this.getCheckListApiCallId = await this.landingPageApiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.checkRideListEndPoint,
    });
  };

  getChecklistApiSucessCallBack = (response: ChecklistResponse) => {
    if (response.success) {
      const checklistData = response?.data?.data || []
      this.setState({
        trainingChecklist: checklistData.slice(0, 9),
        instructionsChecklist: checklistData.slice(9, 18),
        flightChecklist: checklistData.slice(18, 28),
        checklistRecord: checklistData
      });
    }
  }

  getChecklistApiFailureCallBack = (response: ErrorResponse) => {
    toast.error(response.errors[0].token)
  }

  upcomongAndPastExamApi = async () => {
    this.upcomingAndPastExamApiCallId = await this.landingPageApiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.upcomingExamEndPoint,
    });
  };

  upcomongExamSucessCallBack = (response: ExamApiResponse) => {
    if (response) {
      const examDataResponse = response.data
      const examDataUpdate = {
        upcoming_exams: examDataResponse.upcoming_exams || [],
        upcoming_exams_count: examDataResponse.upcoming_exams_count,
        past_exams: examDataResponse.past_exams || [],
        past_exams_count: examDataResponse.past_exams_count,
        total_items_count: examDataResponse.total_items_count,
        checked_items_count: examDataResponse.checked_items_count
      }
      this.setState({ examData: examDataUpdate })
    }
  }

  upcomongExamFailureCallBack = (response: ErrorResponse) => {
    toast.error(response.errors[0].token)
  }

  handleCheckLength = (checkListItem: Task[]) => {
    return checkListItem.filter(item => item.attributes.is_checked).length
  }

  handlePastNextExam = () => {
    this.setState((prevState) => ({
      pastExamIndex: (prevState.pastExamIndex + 1),

    }));
  };

  handlePastPreviousExam = () => {
    this.setState((prevState) => ({
      pastExamIndex:
        (prevState.pastExamIndex - 1),
    }));
  };

  handleUpcomNextExam = () => {
    this.setState((prevState) => ({
      upcomingExamIndax: (prevState.upcomingExamIndax + 1),
    }));
  };

  handleUpcomPreviousExam = () => {
    this.setState((prevState) => ({
      upcomingExamIndax:
        (prevState.upcomingExamIndax - 1),
    }));
  };

  handleProgress = (part: number, total: number) => {
    return Math.floor((part / total) * 100);
  }

  // Customizable Area End
}
